import { ChangeEvent, KeyboardEvent, useState } from 'react';

import { AnySchema } from 'joi';
import { FormPrefix } from './formPrefix';

type PropTypes = {
  type: string,
  disabled?: boolean,
  label: string,
  value: any,
  schema: AnySchema,
  onChange: (value: any) => void,
  onSubmit?: () => void,
  touchedOverride?: boolean,
  autocomplete?: string,
  min?: string,
  prefix?: string,
  hideLabel?: boolean,
}

export const Input: React.FC<PropTypes> = ({ type, disabled = false, label, value, schema, onChange, onSubmit, touchedOverride, autocomplete, min, prefix, hideLabel }) => {
  const [touched, setTouched] = useState<boolean>(false);

  const { error } = schema.validate(value);
  const validationMessage = error?.message.replace('"value"', label);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    onChange(event.currentTarget.value)
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onSubmit) {
      onSubmit();
    }
  }

  const showError = value?.length > 0 && (touchedOverride || touched) && error;

  const input = (<input
    type={type}
    disabled={disabled}
    placeholder={label}
    autoComplete={autocomplete}
    value={value}
    onChange={onInputChange}
    onKeyDown={onKeyDown}
    className={showError ? 'error' : undefined}
    min={min}
  />)

  return (
    <>
      {!hideLabel &&
        <label>{label}</label>
      }
      {showError && <p className="error-message">{validationMessage}</p>}
      {prefix ?
        <FormPrefix value={prefix} size={4} siblingInput={input} />
        :
        (input)
      }
    </>
  )
};