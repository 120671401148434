import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Input } from '../form/validatedInput';
import Loader from '../global/loader';
import { Select } from '../form/validatedSelect';
import { redirectSchema } from '../../store/redirects/schema';
import { statusCodeOptions } from '../../helpers/factory';

export const EditRedirect: React.FC<{ redirect: Redirect, updateRedirect: (redirect: Redirect) => void, onSubmit: (redirect: Redirect) => void}> = ({ redirect, updateRedirect, onSubmit }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);

  const { isLoading, error } = useSelector(
    (state: MainState) => ({
      isLoading: state.redirects.isUpdating,
      error: state.redirects.updateError
    }),
    shallowEqual
  );

  const onUpdateRedirect = () => {
    setSubmitted(true);
    const { error } = redirectSchema.validate(redirect);
    if (!error) {
      onSubmit(redirect);
    }
  }

  return (
    <div className="modal-form">
      <Loader isLoading={isLoading} />
      {!isLoading &&
        <>
          {error && <p className='error-message'>{error?.message}</p>}
          <div className='modal-form-fields-scroll-wrapper'>
            <fieldset className="modal-form-fields">
              <Input
                type="text"
                label="Key"
                value={redirect.key}
                schema={redirectSchema.extract('key')}
                onChange={(value: string) => updateRedirect({ ...redirect, key: value })}
                touchedOverride={submitted}
              />
              <Input
                type="text"
                label="Host"
                value={redirect.host}
                schema={redirectSchema.extract('host')}
                onChange={(value: string) => updateRedirect({ ...redirect, host: value })}
                touchedOverride={submitted}
                />
              <Input
                type="text"
                label="Uri"
                value={redirect.uri}
                schema={redirectSchema.extract('uri')}
                onChange={(value: string) => updateRedirect({ ...redirect, uri: value })}
                touchedOverride={submitted}
              />
              <Select
                label="Status"
                options={statusCodeOptions}
                value={redirect.status.toString()}
                schema={redirectSchema.extract('status')}
                onChange={(value: string) => updateRedirect({ ...redirect, status: parseInt(value)})}
                touchedOverride={submitted}
              />
            </fieldset>
          </div>

          <div className='modal-form-button-wrapper'>
            <button onClick={onUpdateRedirect}>Save</button>
          </div>
        </>
      }
    </div>
  )
}