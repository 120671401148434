import * as actions from './constants';

const initialState: TenantState = {
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  tenants: [],
}

const reducer = (
  state: TenantState = initialState,
  action: TenantAction
): TenantState => {
  switch (action.type) {
    case actions.SET_LOADING_TENANTS:
      return { ...state, isLoading: true, loadError: undefined }
    case actions.LOAD_TENANTS:
      if (action.response.error) {
        return { ...state, isLoading: false, loadError: action.response.error };
      }
      return {
        ...state,
        isLoading: false,
        tenants: action.response.tenants
      }
    case actions.SET_CREATING_TENANT:
      return { ...state, isCreating: true, createError: undefined}
    case actions.CREATE_TENANT:
      if (action.response.error) {
        return { ...state, isCreating: false, createError: action.response.error };
      }
      return {
        ...state,
        isCreating: false,
        tenants: [...state.tenants, action.response.tenant],
      }
    case actions.SET_UPDATING_TENANT:
      return { ...state, isUpdating: true, updateError: undefined }
    case actions.UPDATE_TENANT:
      if (action.response.error) {
        return { ...state, isUpdating: false, updateError: action.response.error };
      }
      const tenant = action.response.tenant;
      return {
        ...state,
        isUpdating: false,
        tenants: state.tenants.map((t) => t.tenantId === tenant.tenantId ? tenant : t),
      }
    case actions.SET_DELETING_TENANT:
      return { ...state, isDeleting: true, deleteError: undefined }
    case actions.DELETE_TENANT:
      if (action.response.error) {
        return { ...state, isDeleting: false, deleteError: action.response.error };
      }
      return {
        ...state,
        isDeleting: false,
        tenants: state.tenants.filter((tenant) => tenant.tenantId !== action.response.tenant.tenantId),
      }
    case actions.RESET:
      return initialState;
    default: return state;
  }
}

export default reducer