import { useEffect, useState } from "react"
import config from "../../helpers/config"
import { request } from "../../helpers/request"
import { Input } from "../form/validatedInput"
import { tenantSchema } from "../../store/tenants/schema"
import BinIcon from '../../assets/bin.svg'
import PlusIcon from '../../assets/plus-svgrepo-com.svg'

import './styles.css'
import Modal from "../global/modal"
import { EditTickerMessage } from "./editTickerMessage"

export const EditTicker: React.FC = () => {

  const [tickerMessages, setTickerMessages] = useState<TickerMessages>({})
  const [newTenant, setNewTenant] = useState<string>('')
  const [saveResultMessage, setSaveResultMessage] = useState<string>('')

  const [modalSettings, setModalSettings] = useState<{
    isOpen: boolean,
    tenant?: string
    isEdit: boolean
    messageIndex?: number,
    message?: TickerMessage
  }>({
    isOpen: false,
    isEdit: false,
    tenant: undefined
  })

  useEffect(() => {
    request('GET', `${config.apiUrl}/ticker`).then(v => setTickerMessages(v))
  }, [])

  const onAddTenant = (): void => {
    if (newTenant.length) {
      setTickerMessages({ ...tickerMessages, [newTenant]: [] })
    }
  }

  const onAddMessage = (tenant: string): void => {
    setModalSettings({
      tenant,
      isEdit: false,
      isOpen: true
    })
  }

  const onRemoveMessage = (tenant: string, messageNum: number): void => {
    tickerMessages[tenant].splice(messageNum, 1)
    setTickerMessages({ ...tickerMessages })
  }

  const onSave = (): void => {
    Object.keys(tickerMessages).forEach(tenant => tickerMessages[tenant].forEach(msg => {
      msg.background = '#196ed5'
      msg.color = 'white'
    }))
    request('PUT', `${config.apiUrl}/ticker`, tickerMessages)
      .then(() => {
        setSaveResultMessage(`Saved successfully`)

      })
      .catch(err => {
        setSaveResultMessage(err.message)
      })
  }

  const onModalSave = (message: TickerMessage): void => {

    if (modalSettings.isEdit) {
      tickerMessages[modalSettings.tenant!][modalSettings.messageIndex!] = message
    } else {
      tickerMessages[modalSettings.tenant!].push(message)
    }
    setModalSettings({ ...modalSettings, isOpen: false })
  }

  return (
    <>
      <h1 className="header">Ticker messages</h1>
      {Object.entries(tickerMessages).map(([tenant, messages]) => (
        <div>
          <b>{tenant}</b>

          <Modal handleClose={() => setModalSettings({ ...modalSettings, isOpen: false })} isOpen={modalSettings.isOpen}>
            <EditTickerMessage onSave={onModalSave} message={modalSettings.message}></EditTickerMessage>
          </Modal>

          {messages.map((message, i) => {
            return (
              <div className="message-container">
                <div className="message">

                <a
                  href={message.url}
                  title="Link"
                  >
                  {message.heading} &nbsp;
                  <span>{message.text}</span>
                </a>
                </div>
                <div className="message-controls">
                  <button onClick={() => setModalSettings({
                    tenant,
                    isOpen: true,
                    isEdit: true,
                    messageIndex: i,
                    message
                  })}>Edit</button>

                  <img src={BinIcon} alt="Bin Icon" className="bin-icon" width="20" height="30" onClick={() => onRemoveMessage(tenant, i)} />
                </div>
                

              </div>
            )
          })}

          <div className="add-message-container">
            <div className="add-message-text">
              Add message
            </div>
            <img src={PlusIcon} alt="Plus Icon" className="add-message-icon" width="20" height="30" onClick={() => onAddMessage(tenant)} />
          </div>

        </div>
      ))
      }

      <div className="add-tenant-container">
        <Input
          type='text'
          label="Add Tenant"
          value={newTenant}
          schema={tenantSchema.extract('location')}
          onChange={(value) => setNewTenant(value)}
          touchedOverride={undefined}
          hideLabel={true}
        ></Input>
        <button className="button" onClick={() => onAddTenant()}>Add Tenant</button>
      </div>

      <div>
        <button className="button" onClick={() => onSave()}>Save</button>
        <br />
        {saveResultMessage}
      </div>

    </>
  )

}