import React, { useCallback, useEffect, useState } from 'react'
import { defaultTenant } from '../../helpers/factory';
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { Dispatch } from 'redux'
import { TenantLogos } from './tenantLogos';
import Modal from '../global/modal';
import { useCloseEditAfterLoad } from './hooks/useCloseEditAfterLoad';
import { addLogo, initialiseLogo, loadAllowedTenants } from '../../store/manage/actions';

export const ManageList: React.FC = () => {

  const { tenants, username } = useSelector(
    (state: MainState) => ({
      tenants: state.manage.allowedTenants,
      username: state.authentication.username,
    }),
    shallowEqual
  );

  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    if(username) {
      dispatch(loadAllowedTenants(username));
    }
  }, [dispatch]);

  const [ activeTenant, setActiveTenant] = useState<Tenant>(defaultTenant());
  const { isEditOpen, setIsEditOpen } = useCloseEditAfterLoad();

  const onChangeTenant = (tenantId: string) => {
    const tenant: Tenant | undefined = tenants.find(ten => ten.tenantId === tenantId)
    if (tenant) setActiveTenant(tenant)
  }

  const onOpenEditLogos = (tenant: Tenant) => {
    setActiveTenant(tenant);
    setIsEditOpen(true);
    resetLogoState()
  }

  const resetLogoState = useCallback(() => dispatch(initialiseLogo()), [dispatch])

  const onEditLogos = useCallback(
    (logo: LogoDetails) => {
      dispatch(addLogo(logo))
    },
    [dispatch]
  );

  return (
    <main>
      <h1>Manage</h1>
      <Modal handleClose={() => setIsEditOpen(false)} isOpen={isEditOpen}>
        <TenantLogos onUpdateTenant={onChangeTenant} tenant={activeTenant} onSubmit={onEditLogos} />
      </Modal>
      <table className="react-table">
            <thead>
              <tr>
                <th>Tenants you can manage</th>
                <th>Location</th>
                <th>Edit tenant</th>
              </tr>
            </thead>
            <tbody>
            {tenants.map(tenant => (
                <tr key={tenant.name}>
                  <td>{tenant.name}</td>
                  <td>{tenant.location}</td>
                  <td className="manageListEditContainer">
                    <button title="Add Logo" onClick={() => onOpenEditLogos(tenant)} className={`button-small icon-pencil`}></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
    </main>
  );
}