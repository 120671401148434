import React from 'react';
import { useSelector } from 'react-redux';
import Toast from './toast';

const ToastList: React.FC = () => {
  const toasts = useSelector((state: MainState) => state.passManagement.toasts);

  return (
    <div className="toast-container">
      {
        toasts.map((toastNotification: ToastNotification, index: number) => {
          return (
            <Toast toast={toastNotification} i={index} />
          )
        })
      }
    </div>
  );
};

export default ToastList;
