import * as actions from './constants';

import config from '../../helpers/config';
import { request } from '../../helpers/request';

export function resetUsersState() {
  return (dispatch: UserDispatchType) => {
    dispatch({ type: actions.RESET });
  }
}

export function loadUsers() {
  const action: JobAction = {
    type: actions.LOAD_USERS
  }

  return async (dispatch: UserDispatchType) => {
    try {
      dispatch({ type: actions.SET_LOADING_USERS });
      const data = await request('GET', `${config.apiUrl}/users`);
      dispatch({ ...action, response: { users: data.users } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function createUser(user: User) {
  const action: UserAction = { type: actions.CREATE_USER };

  return async (dispatch: UserDispatchType) => {
    try {
      dispatch({ type: actions.SET_CREATING_USER });
      const body = { user };
      const data = await request('POST', `${config.apiUrl}/users`, body);
      dispatch({ ...action, response: { user: data.user } });
    } catch (error) {
      console.log(error)
      dispatch({ ...action, response: { error } });
    }
  }
}

export function updateUser(user: User) {
  const action: UserAction = { type: actions.UPDATE_USER };

  return async (dispatch: UserDispatchType) => {
    try {
      dispatch({ type: actions.SET_UPDATING_USER });
      const body = { user };
      const data = await request('PUT', `${config.apiUrl}/users`, body);
      dispatch({ ...action, response: { user: data.user } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function deleteUser(user: User) {
  const action: UserAction = { type: actions.DELETE_USER };

  return async (dispatch: UserDispatchType) => {
    try {
      dispatch({ type: actions.SET_DELETING_USER });
      await request('DELETE', `${config.apiUrl}/users/${user.username}`);
      dispatch({ ...action, response: { user: user } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}