import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { removeToast } from "../../store/passManagement/actions";

type PropTypes = {
    toast: ToastNotification,
    i: number
}

const Toast: React.FC<PropTypes> = ({ toast, i }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        const toastTimeout = setTimeout(() => {
            dispatch(removeToast(toast));
        }, 5000);

        return () => {
            clearTimeout(toastTimeout);
        }
    }, []);

    return (
        <div key={i} className={`toast ${toast.type}`}>
            <div className="toast-indicator"></div>
            <div className="toast-content">
                {toast.message}
            </div>
        </div>
    )
}

export default Toast;