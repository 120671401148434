import config from '../../helpers/config';
import { externalRequest, request } from '../../helpers/request';
import { uploadToS3 } from '../../helpers/s3';
import * as actions from './constants';

export function reset() {
  const action: ManageAction = { type: actions.RESET }

  return async (dispatch: ManageDispatchType) => {
    dispatch({...action})
  }
}

export function loadAllowedTenants(username: string) {
  const action: JobAction = { type: actions.LOAD_ALLOWED_TENANTS };

  return async (dispatch: JobDispatchType) => {
    try {
    dispatch({ type: actions.SET_LOADING_TENANTS });
    const allBrands = await request('GET', `${config.apiUrl}/users/${username}/allTenants`);
      dispatch({ ...action, response: { brands: allBrands.tenants } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function addLogo(logo: LogoDetails) {
  const action: ManageAction = { type: actions.CREATE_LOGO };

  return async (dispatch: TenantDispatchType) => {
    try {
      dispatch({ type: actions.SET_CREATING_LOGO });
      const s3Url = await request('POST', `${config.apiUrl}/tenants/upload-logo`, {...logo, value: ''});
      await externalRequest('PUT', s3Url , logo.value);
      dispatch({ type: actions.ADD_LOGO, response: { logo } })
      dispatch({ ...action });
    } catch (error) {
      console.log(error)
      dispatch({ type: actions.CREATE_LOGO_ERROR, response: { error } });
    }
  }
}

export function initialiseLogo() {
  const action: ManageAction = { type: actions.RESET_LOGOS }
  return async (dispatch: TenantDispatchType) => {
    dispatch({ ...action })
  }
}