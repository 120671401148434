import { Input as ValidatedInput } from '../../form/validatedInput';
import { jobSchema } from '../../../store/jobs/schema';

type PropTypes = {
  type: string,
  propName: string,
  label: string,
  job: Job,
  updateJob: (job: Job) => void,
  touchedOverride?: boolean
  value?: string,
  disabled?: boolean
}

export const Input: React.FC<PropTypes> = ({ type, propName, label, job, updateJob, touchedOverride, value, disabled = false }) => {

  const onChange = (value: any) => updateJob({ ...job, [propName]: value });
  const schema = jobSchema.extract(propName);

  return (
    <ValidatedInput
      disabled={disabled}
      type={type}
      label={label}
      value={value || (job as any)[propName]}
      schema={schema}
      onChange={onChange}
      touchedOverride={touchedOverride}
    />
  )
};