import { AuthGroup, AuthStatus, isGroupMember } from '../../helpers/authentication';
import { shallowEqual, useSelector } from 'react-redux';

export const SignedIn: React.FC<{ invert?: boolean }> = ({ children, invert }) => {
  const signedIn = useSelector(
    (state: MainState) => state.authentication.authStatus === AuthStatus.SignedIn,
    shallowEqual
  );
  if (invert === true) {
    return (<>{!signedIn && <>{children}</>}</>);
  }
  return (<>{signedIn && <>{children}</>}</>);
}

export const SignedOut: React.FC = ({ children }) => {
  return (<SignedIn invert={true}>{children}</SignedIn>);
}

export const Authenticated: React.FC<{ groups: AuthGroup[] }> = ({ children, groups }) => {
  const { signedIn, authGroups } = useSelector(
    (state: MainState) => ({
      signedIn: state.authentication.authStatus === AuthStatus.SignedIn,
      authGroups: state.authentication.authGroups,
    }),
    shallowEqual
  );
  return (<>{signedIn && isGroupMember(authGroups, groups) && <>{children}</>}</>);
}

export const FulfilmentAuthenticated: React.FC<{ levelRequired: string }> = ({ children, levelRequired }) => {
  const { signedIn, fulfilmentLvl } = useSelector(
    (state: MainState) => ({
      signedIn: state.authentication.authStatus === AuthStatus.SignedIn,
      fulfilmentLvl: state.authentication.fulfilmentAuth?.level,
    }),
    shallowEqual
  );
  let fulfilmentAuthenticated = false;
  const fulfilmentAccessLevels = ['read', 'manager-access']
  if (fulfilmentLvl !== undefined) {
    if (levelRequired === 'read') {
      fulfilmentAuthenticated = fulfilmentAccessLevels.includes(fulfilmentLvl)
    }
    if (levelRequired === 'manager-access') {
      fulfilmentAuthenticated = fulfilmentLvl === levelRequired
    }
  }
  return (<>{signedIn && fulfilmentAuthenticated && <>{children}</>}</>);
}

export const PassManagementAuthenticated: React.FC = ({ children }: any) => {
  const { signedIn, tenants } = useSelector(
    (state: MainState) => ({
      signedIn: state.authentication.authStatus === AuthStatus.SignedIn,
      tenants: state.authentication.passManagementTenants,
    }),
    shallowEqual
  );
  const hasTenants = tenants && tenants.some((ten) => ['MAN', 'STN', 'EMA'].includes(ten))
  return (<>{signedIn && hasTenants && <>{children}</>}</>)
}

// this is to decide whether a user has read or write access to a specific tenant rather than to the portal itself
export const PassManagementWriteAuthenticated: React.FC<any> = ({ tenant, children }) => {
  const { signedIn, passManagementPerms } = useSelector(
    (state: MainState) => ({
      signedIn: state.authentication.authStatus === AuthStatus.SignedIn,
      passManagementPerms: state.authentication.passManagementPermissions,
    }),
    shallowEqual
  );
  const writePermission = "write"
  const hasTenants = passManagementPerms && passManagementPerms[tenant] === writePermission
  return (<>{signedIn && hasTenants && <>{children}</>}</>)
}

export const AdminAuthenticated: React.FC = ({ children }) => {
  return (<Authenticated groups={[AuthGroup.Admin]}>{children}</Authenticated>);
}

export const RedirectAuthenticated: React.FC = ({ children }) => {
  return (<Authenticated groups={[AuthGroup.Admin, AuthGroup.RedirectEditor]}>{children}</Authenticated>);
}

export const TickerAuthenticated: React.FC = ({ children }) => {
  return (<Authenticated groups={[AuthGroup.Admin, AuthGroup.RedirectEditor]}>{children}</Authenticated>);
}


export const RecruitmentEditorAuthenticated: React.FC = ({ children }) => {
  return (<Authenticated groups={[AuthGroup.Admin, AuthGroup.RecruitmentEditor]}>{children}</Authenticated>);
}

export const RecruitmentAuthenticated: React.FC = ({ children }) => {
  return (<Authenticated groups={[AuthGroup.Admin, AuthGroup.RecruitmentEditor, AuthGroup.RecruitmentApproval]}>{children}</Authenticated>);
}

export const RecruitmentApprovalAuthenticated: React.FC = ({ children }) => {
  return (<Authenticated groups={[AuthGroup.Admin, AuthGroup.RecruitmentApproval]}>{children}</Authenticated>);
}

export const FulfilmentReadAuthenticated: React.FC = ({ children }) => {
  return (<FulfilmentAuthenticated levelRequired="read">{children}</FulfilmentAuthenticated>)
}

export const FulfilmentManagerAuthenticated: React.FC = ({ children }) => {
  return (<FulfilmentAuthenticated levelRequired="manager-access">{children}</FulfilmentAuthenticated>)
}