import { shallowEqual, useSelector } from 'react-redux';

import { AuthTenant } from '../../helpers/authentication';
import { locationText } from '../../helpers/factory';

type PropTypes = {
  disabled?: boolean,
  value: AuthTenant,
  optionsProp?: "locations" | "passManagementTenants"
  onChange: (value: AuthTenant) => void
}

export const LocationSelect: React.FC<PropTypes> = ({ value, onChange, optionsProp = "locations" }) => {
  const locations: AuthTenant[] = useSelector(
    (state: MainState) => state.authentication[optionsProp] || [],
    shallowEqual
  );

  return (
    <>
      <select value={value} onChange={(e) => onChange(e.currentTarget.value as AuthTenant)}>
        {locations?.map((location) => { return (<option key={location} value={location}>{locationText(location)}</option>); })}
      </select>
    </>
  )
};