import * as actions from './constants';

import config from '../../helpers/config';
import { request } from '../../helpers/request';

export function resetTenantsState() {
  return (dispatch: TenantDispatchType) => {
    dispatch({ type: actions.RESET });
  }
}

export function loadTenants() {
  const action: JobAction = {
    type: actions.LOAD_TENANTS
  }

  return async (dispatch: TenantDispatchType) => {
    try {
      dispatch({ type: actions.SET_LOADING_TENANTS });
      const data = await request('GET', `${config.apiUrl}/tenants`);
      dispatch({ ...action, response: { tenants: data.tenants } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function createTenant(tenant: Tenant) {
  const action: TenantAction = { type: actions.CREATE_TENANT };

  return async (dispatch: TenantDispatchType) => {
    try {
      dispatch({ type: actions.SET_CREATING_TENANT });
      const body = { tenant };
      const data = await request('POST', `${config.apiUrl}/tenants`, body);
      dispatch({ ...action, response: { tenant: data.tenant } });
    } catch (error) {
      console.log(error)
      dispatch({ ...action, response: { error } });
    }
  }
}

export function updateTenant(tenant: Tenant) {
  const action: TenantAction = { type: actions.UPDATE_TENANT };

  return async (dispatch: TenantDispatchType) => {
    try {
      dispatch({ type: actions.SET_UPDATING_TENANT });
      const body = { tenant };
      const data = await request('PUT', `${config.apiUrl}/tenants/update`, body);
      dispatch({ ...action, response: { tenant: data.tenant } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function deleteTenant(tenant: Tenant) {
  const action: TenantAction = { type: actions.DELETE_TENANT };

  return async (dispatch: TenantDispatchType) => {
    try {
      dispatch({ type: actions.SET_DELETING_TENANT });
      await request('DELETE', `${config.apiUrl}/tenants/${tenant.tenantId}`);
      dispatch({ ...action, response: { tenant: tenant } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}