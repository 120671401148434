import { enumOptions, locationText, statusText } from '../../../helpers/factory';

import { Input } from './input';
import { Select as ValidatedSelect } from '../../form/validatedSelect';
import { jobSchema, JobSector } from '../../../store/jobs/schema';
import { Select } from './select';
import { TextArea } from './textarea';
import { shallowEqual, useSelector } from 'react-redux';
import { ChangeEvent, useEffect } from 'react';
import { toIsoDateString } from '../../../helpers/delay';
import { DateInput } from './dateInput';
import { Checkbox } from '../../form/checkbox';

type PropTypes = {
  job: Job,
  showAdminFields: boolean,
  updateJob: (job: Job) => void,
  touchedOverride?: boolean
}

export const JobFields: React.FC<PropTypes> = ({ job, showAdminFields, updateJob, touchedOverride }) => {

  const { brands } = useSelector(
    (state: MainState) => ({
      brands: state.jobs.brands
    }),
    shallowEqual
  );

  useEffect(() => {
    //default job location
    onUpdateBrand(job.brand || brands[0].tenantId || "")
  }, [])

  const onTermsCheck = (event: ChangeEvent<HTMLInputElement>) => {
    updateJob({...job, terms: event.target.checked})
  };

  const onUpdateBrand = (value: string) => {
    const airport = brands.find(brand => brand.tenantId === value)?.location
    updateJob({
      ...job,
      brand: value,
      location: location(value),
      tenant: airport,
      logo: ''
    })
  }
  
  const logoOptions = () => {
    const brand = brands.find((brand) => job.brand === brand.tenantId)
    return brand?.logoTitles?.map((logo) => ({
      value: logo,
      label: logo
    })) || []
  }
  
  const brandOptions = brands.map(brand => ({ 
    value: brand.tenantId, 
    label: brand.name }
    ))

    const initialiseLogo = () => {
      const logos = logoOptions()
  
      if(!job.logo && logos[0]) {
        updateJob({
          ...job,
          logo: logos[0].value
        })
      } else if(job.logo && !logos[0]) {
        updateJob({
          ...job,
          logo: ''
        })
      }
    }

    useEffect(() => {
      initialiseLogo()
    }, [brands, job.brand])

    
  const location = (brandId: string) => `${locationText(brands.find(brand => brand.tenantId === brandId)?.location)}, GB`
  const fields = [
    { type: "text", prop: "title", label: "Title" },
    {
      type: "brand",
      prop: "brand",
      label: "Employer",
      options: brandOptions,
      disabled: true,
    },
    {
      type: "select",
      prop: "segments",
      label: "Sector",
      options: enumOptions(JobSector, !showAdminFields),
    },
    { type: "text", prop: "contract", label: "Contract" },
    { type: "text", prop: "url", label: "Application Link or Email"},
    // choose existing logos section
    { type: "text", prop: "location", label: "Location", disabled: true },
    { type: "date", prop: "expires", label: "Closing Date" },
    { type: "text", prop: "shifttype", label: "Hours" },
    { type: "text", prop: "compensation", label: "Salary" },
    { type: "textarea", prop: "description", label: "Decription" },
    { type: "select", prop: "logo", label: "Choose a Logo", options: logoOptions() }
  ];

  if(job.createdBy !== "SuccessFactors") {
    fields.push({ type: "checkbox", prop: "terms", label: "Terms" })
  }

  return (
    <>
      {showAdminFields &&
        <>
          <label>Created</label>
          <input type='text' disabled={true} value={job.created} />
          <label>Created By</label>
          <input type='text' disabled={true} value={job.createdBy} />
          <label>Updated</label><input type='text' disabled={true} value={job.updated} />
          <label>Updated By</label>
          <input type='text' disabled={true} value={job.updatedBy} />
          <label>Approval Status</label>
          <input type='text' disabled={true} value={statusText(job.approvalStatus)} />
          <label>Approval Notes</label>
          <textarea disabled={true} value={job.approvalNotes} />
          <hr />
        </>
      }
      {fields.map((field) => {
        switch (field.type) {
          case "text":
            return <Input
              disabled={field.disabled}
              key={field.prop}
              type="text"
              label={field.label}
              propName={field.prop}
              job={job}
              updateJob={updateJob}
              touchedOverride={touchedOverride}
            />
          case "checkbox":
            return (<><Checkbox 
            key={field.prop}
            type="checkbox"
            label={field.label}
            onChange={onTermsCheck}
            value={job.terms}
            schema={jobSchema.extract('terms')}
            touchedOverride={touchedOverride}
            /><span style={{"marginLeft": "5px"}}>I agree to the <a href="https://stanstedairportacademy.com/terms-and-conditions/">terms and conditions</a> when posting this job.</span></>)
          case "textarea":
            return <TextArea
              key={field.prop}
              rows={15}
              label={field.label}
              propName={field.prop}
              job={job}
              updateJob={updateJob}
              touchedOverride={touchedOverride}
            />
          case "select":
            return <Select
              key={field.prop}
              label={field.label}
              propName={field.prop}
              options={field.options as SelectOption[]}
              job={job}
              updateJob={updateJob}
              touchedOverride={touchedOverride}
            />
          case "date":
            return <DateInput
            type={field.type}
            key={field.prop}
            label={field.label}
            value={job.expires}
            propName={field.prop}
            min={toIsoDateString()}
            job={job}
            updateJob={updateJob}
            touchedOverride={touchedOverride}
          />
          case "brand": 
          return (
            <ValidatedSelect
              options={brandOptions}
              label={field.label}
              value={job.brand || ""}
              schema={jobSchema.extract('brand')}
              onChange={onUpdateBrand}
              touchedOverride={touchedOverride}
              key={field.prop}
            />
          )
          default:
            throw new Error(`Invalid type: ${field.type}`);
        }
      })
      }
    </>
  );
};