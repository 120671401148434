import { ApprovalStatus, jobSchema } from '../../store/jobs/schema';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { JobFields } from './form/fields';
import Loader from '../global/loader';
import Modal from '../global/modal';
import { RecruitmentApprovalAuthenticated } from '../authentication/authenticated';
import { UpdateJobApproval } from './updateJobApproval';
import { isSuccessFactors } from '../../helpers/jobs';

type PropTypes = {
  job: Job,
  updateJob: (job: Job) => void,
  onSubmit: (job: Job) => void,
  onUpdateApprovalStatus: (job: Job, approvalStatus: ApprovalStatus, approvalNotes?: string) => void
}

export const EditJob: React.FC<PropTypes> = ({ job, updateJob, onSubmit, onUpdateApprovalStatus }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<any>();
  const [isConfirmApproveOpen, setIsConfirmApproveOpen] = useState(false);
  const [isConfirmRejectOpen, setIsConfirmRejectOpen] = useState(false);

  const successFactorsRole = isSuccessFactors(job)

  const { isLoading, error, isUpdatingApprovalStatus, approvalError } = useSelector(
    (state: MainState) => ({
      isLoading: state.jobs.isUpdating,
      error: state.jobs.updateError,
      isUpdatingApprovalStatus: state.jobs.isUpdatingApprovalStatus,
      approvalError: state.jobs.updateApprovalStatusError
    }),
    shallowEqual
  );

  useEffect(() => {
    const { error } = jobSchema.validate(job);
    setValidationError(error);
  }, [job]);

  useEffect(() => {
    if (!isUpdatingApprovalStatus && !approvalError) {
      setIsConfirmApproveOpen(false);
      setIsConfirmRejectOpen(false);
    }
  }, [isUpdatingApprovalStatus, approvalError]);

  const onSaveButtonClick = () => {
    setSubmitted(true);
    const { error } = jobSchema.validate(job);
    if (!error) {
      onSubmit(job);
    }
  }

  const onSaveAndApproveButtonClick = () => {
    setSubmitted(true);
    const { error } = jobSchema.validate(job);
    if (!error) {
      onSubmit(job);
    }
  }

  return (
    <div className="modal-form">
      <Loader isLoading={isLoading} />
      {!isLoading &&
        <>
          {error && <p className='error-message'>{error?.message}</p>}
          <div className='modal-form-fields-scroll-wrapper'>
            <fieldset className="modal-form-fields">
              <JobFields job={job} showAdminFields={true} updateJob={updateJob} touchedOverride={submitted} />
            </fieldset>
          </div>

          <div className='modal-form-button-wrapper'>
            {submitted && validationError && <p className='error-message'>{validationError.message}</p>}
            <button disabled={successFactorsRole || validationError !== undefined} onClick={onSaveButtonClick}>Save</button>
            {[ApprovalStatus.Rejected].includes(job.approvalStatus) &&
                  <button onClick={onSaveAndApproveButtonClick}>Update And Request Approval</button>
            }
            <RecruitmentApprovalAuthenticated>
              {[ApprovalStatus.AwaitingApproval, ApprovalStatus.Rejected].includes(job.approvalStatus) &&
                <>
                  <button disabled={successFactorsRole || validationError !== undefined} onClick={() => setIsConfirmApproveOpen(true)}>Approve</button>
                  <Modal handleClose={() => setIsConfirmApproveOpen(false)} isOpen={isConfirmApproveOpen}>
                    <UpdateJobApproval job={job} approvalStatus={ApprovalStatus.Approved} onUpdateApprovalStatus={onUpdateApprovalStatus} />
                  </Modal>
                </>
              }
              {[ApprovalStatus.AwaitingApproval, ApprovalStatus.Approved].includes(job.approvalStatus) &&
                <>
                  <button disabled={successFactorsRole} onClick={() => setIsConfirmRejectOpen(true)}>Reject</button>
                  <Modal handleClose={() => setIsConfirmRejectOpen(false)} isOpen={isConfirmRejectOpen}>
                    <UpdateJobApproval job={job} approvalStatus={ApprovalStatus.Rejected} onUpdateApprovalStatus={onUpdateApprovalStatus} />
                  </Modal>
                </>
              }
            </RecruitmentApprovalAuthenticated>
          </div>
        </>
      }
    </div>
  );
}