import * as Joi from 'joi';

export const emailSchema = Joi.string().email({ tlds: { allow: false } }).required();

export const validateEmail = (email: string) => emailSchema.validate(email);

export const usernameSchema = Joi.string().min(3).required();

export const validateUsername = (username: string) => usernameSchema.validate(username);

export const passwordSchema = Joi.string()
  .pattern(new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$'))
  .required()
  .error(new Error('Password must be at least 12 characters and include an uppercase, lowercase, number and symbol'));

export const validatePassword = (password: string) => passwordSchema.validate(password);

export const codeSchema = Joi.string().min(6).required();

export const validateCode = (code: string) => codeSchema.validate(code);

export const signUpSchema = Joi.object({
  email: emailSchema,
  username: usernameSchema,
  password: passwordSchema,
  confirmPassword: Joi.string().allow(Joi.ref('password')).required().error(new Error('Passwords must match'))
});

export const validateSignUp = (form: { email: string, username: string, password: string, confirmPassword: string }) => signUpSchema.validate(form);

export const signInPasswordSchema = Joi.string().required();

export const validateSignInPassword = (password: string) => signInPasswordSchema.validate(password);

export const signInSchema = Joi.object({
  username: usernameSchema,
  password: signInPasswordSchema
});

export const validateSignIn = (form: { username: string, password: string }) => signInSchema.validate(form);

export const verifyCodeSchema = Joi.object({
  username: usernameSchema,
  code: codeSchema
});

export const validateVerifyCode = (form: { username: string, code: string }) => verifyCodeSchema.validate(form);

export const changePasswordSchema = Joi.object({
  oldPassword: signInPasswordSchema,
  newPassword: passwordSchema
});

export const validateChangePassword = (form: { oldPassword: string, newPassword: string }) => changePasswordSchema.validate(form);

export const resetPasswordSchema = Joi.object({
  username: usernameSchema,
  code: codeSchema,
  password: passwordSchema,
  confirmPassword: Joi.string().allow(Joi.ref('password')).required().error(new Error('Passwords must match'))
});

export const validateResetPassword = (form: { username: string, code: string, password: string, confirmPassword: string }) => resetPasswordSchema.validate(form);