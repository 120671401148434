import { Link } from 'react-router-dom';
import NavigationBar from '../components/navigation/navigationBar';

const NotFound: React.FC = () => {
  return (
    <div className="wrapper">
      <NavigationBar />

      <header className="header" id="home">
        <section className="container">
          <img
            className="img"
            src="https://assets.live.dxp.maginfrastructure.com/f/73114/80x50/591408d8a4/mag-blue.svg"
            alt="MAG"
            title="MAG"
          />
          <h1 className="title">404</h1>
          <p className="description">The page you are looking for does not exist</p>
          <Link className="button" to="/">Home</Link>
        </section>
      </header>
    </div>
  );
}

export default NotFound;