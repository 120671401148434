import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { JobFields } from './form/fields';
import Loader from '../global/loader';
import { defaultJob } from '../../helpers/factory';
import { jobSchema } from '../../store/jobs/schema';

export const CreateJob: React.FC<{ onSubmit: (job: Job) => void }> = ({ onSubmit }) => {
  const [job, setJob] = useState<Job>(defaultJob());
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<any>();

  const { isLoading, createError } = useSelector(
    (state: MainState) => ({
      isLoading: state.jobs.isCreating,
      createError: state.jobs.createError
    }),
    shallowEqual
  );

  useEffect(() => {
    const { error } = jobSchema.validate(job);
    setValidationError(error);
  }, [job]);

  const onButtonClick = () => {
    setSubmitted(true);
    const { error } = jobSchema.validate(job);
    if (!error) {
      onSubmit(job);
    }
  }

  return (
    <div className="modal-form">
      <Loader isLoading={isLoading} />
      {!isLoading &&
        <>
          {createError && <p className='error-message'>{createError?.message}</p>}
          <div className='modal-form-fields-scroll-wrapper'>
            <fieldset className="modal-form-fields">
              <JobFields job={job} showAdminFields={false} updateJob={setJob} touchedOverride={submitted} />
            </fieldset>
          </div>

          <div className='modal-form-button-wrapper'>
            {submitted && validationError && <p className='error-message'>{validationError.message}</p>}
            <button disabled={validationError !== undefined} onClick={onButtonClick}>Save</button>
          </div>
        </>
      }
    </div>
  )
}