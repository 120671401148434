import NavigationBar from '../components/navigation/navigationBar';
import { ManageTenants } from '../components/tenants/manageTenants';

const Tenants: React.FC = () => {
  return (
    <div className="wrapper">
      <NavigationBar />
      <section className="container">
        <ManageTenants />
      </section>
    </div>
  );
}

export default Tenants