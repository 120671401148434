export const SET_LOADING_PASSES = 'PASSES_SET_LOADING_PASSES';
export const LOAD_PASSES = 'PASSES_LOAD_PASSES';
export const SET_CREATING_PASS= 'PASSES_SET_CREATING_PASS';
export const CREATE_PASS = 'PASSES_CREATE_PASS';
export const SET_UPDATING_PASS = 'PASSES_SET_UPDATING_PASS';
export const UPDATE_PASS = 'PASSES_UPDATE_PASS';
export const SET_DELETING_PASS = 'PASSES_SET_DELETING_PASS';
export const DELETE_PASS = 'PASSES_DELETE_PASS';
export const SET_UPDATING_PASS_APPROVAL_STATUS = 'PASSES_SET_UPDATING_PASS_APPROVAL_STATUS';
export const UPDATE_PASS_APPROVAL_STATUS = 'PASSES_UPDATE_PASS_APPROVAL_STATUS';
export const RESET = 'PASSES_RESET';
export const SET_SEARCHING_PASSES = 'PASSES_SET_SEARCHING_PASSES';
export const SET_SEARCH_ERROR = 'PASSES_SET_SEARCH_ERROR';
export const SET_SEARCH_RESULTS = 'PASSES_SET_SEARCH_RESULTS';

export const SET_CREATING_FILE= 'FILES_SET_CREATING_FILE';
export const CREATE_FILE = 'FILES_CREATE_FILE';
export const SET_UPDATING_FILE = 'FILES_SET_UPDATING_FILE';
export const UPDATE_FILE = 'FILES_UPDATE_FILE';
export const SET_DELETING_FILE = 'FILES_SET_DELETING_FILE';
export const DELETE_FILE = 'FILES_DELETE_FILE';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const CREATE_FILE_ERROR = 'CREATE_FILE_ERROR';

export const ALREADY_EXISTS_ERROR = 'duplicate key value violates unique constraint '
export const ADD_TOAST = 'ADD_TOAST_NOTIFICATION';
export const REMOVE_TOAST = 'REMOVE_TOAST_NOTIFICATION';
