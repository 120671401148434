import * as actions from './constants';

const initialState: RedirectState = {
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  redirects: [],
}

const reducer = (
  state: RedirectState = initialState,
  action: RedirectAction
): RedirectState => {
  switch (action.type) {
    case actions.SET_LOADING_REDIRECTS:
      return { ...state, isLoading: true, loadError: undefined }
    case actions.LOAD_REDIRECTS:
      if (action.response.error) {
        return { ...state, isLoading: false, loadError: action.response.error };
      }
      return {
        ...state,
        isLoading: false,
        redirects: action.response.redirects
      }
    case actions.SET_CREATING_REDIRECT:
      return { ...state, isCreating: true, createError: undefined }
    case actions.CREATE_REDIRECT:
      if (action.response.error) {
        return { ...state, isCreating: false, createError: action.response.error };
      }
      return {
        ...state,
        isCreating: false,
        redirects: state.redirects.concat(action.response.redirect),
      }
    case actions.SET_UPDATING_REDIRECT:
      return { ...state, isUpdating: true, updateError: undefined }
    case actions.UPDATE_REDIRECT:
      if (action.response.error) {
        return { ...state, isUpdating: false, updateError: action.response.error };
      }
      const redirect = action.response.redirect;
      return {
        ...state,
        isUpdating: false,
        redirects: state.redirects.map((r) => r.key === redirect.key ? redirect : r),
      }
    case actions.SET_DELETING_REDIRECT:
      return { ...state, isDeleting: true, deleteError: undefined }
    case actions.DELETE_REDIRECT:
      if (action.response.error) {
        return { ...state, isDeleting: false, deleteError: action.response.error };
      }
      return {
        ...state,
        isDeleting: false,
        redirects: state.redirects.filter((redirect) => redirect.key !== action.response.redirect.key),
      }
    case actions.RESET:
      return initialState;
    default: return state;
  }
}

export default reducer