import { useLocation } from 'react-router-dom';
import useBulkUploadRecords from "./hooks/useBulkUploadRecords";
import Loader from "../global/loader";

const BulkAuditTable: React.FC = () => {
    const location = useLocation()
    const { tenant } = location.state as any
    const { items, refresh, resultsLoading } = useBulkUploadRecords(tenant)

    const formatDateStr = (dateStr: string) => {
        const date = new Date(dateStr);
        const options = { timeZone: 'Europe/London', hour12: false };
        const formattedDate = date.toLocaleString('en-GB', options);
        return formattedDate
    }

    return (
        <main>
            <h1>Excel File Upload Audit</h1>
            <button style={{ width: "100%" }} onClick={() => refresh()}>Get Latest</button>
            {resultsLoading ? <Loader isLoading={true} /> :
                <table className="react-table">
                    <thead>
                        <tr>
                            <th>Airport</th>
                            <th>Status</th>
                            <th>Timestamp</th>
                            <th>Notes</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((record: BulkUploadRecord, i: number) => (
                            <tr key={i}>
                                <td>{record.airport}</td>
                                <td>{record.status}</td>
                                <td className="wfm-broken-cells">{formatDateStr(record.timestamp)}</td>
                                <td className="wfm-broken-cells">
                                    {record.notes}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
        </main>
    )

}

export default BulkAuditTable;