import { AdminAuthenticated, FulfilmentReadAuthenticated, PassManagementAuthenticated, RecruitmentAuthenticated, RedirectAuthenticated, SignedIn, SignedOut } from '../authentication/authenticated';

import { Link } from 'react-router-dom';
import NavigationLink from './navigationLink';
import Notifications from './notifications';
import UserBox from './userBox';
import { Auth } from 'aws-amplify'
import { shallowEqual, useSelector } from 'react-redux';
import { isRecruitmentEditor } from '../../helpers/authentication';

const NavigationBar: React.FC = () => {
  const { isLoading, authGroups } = useSelector(
    (state: MainState) => ({
      isLoading: state.authentication.isSigningIn,
      authGroups: state.authentication.authGroups
    }),
    shallowEqual
  );

  const isRecruitmentAuthed = isRecruitmentEditor(authGroups)

  return (
    <nav className="navigation">
      <UserBox />
      {isRecruitmentAuthed && <Notifications />}

      <section className="container">
        <Link className="navigation-title" to="/">
          <img
            className="img"
            src="https://assets.live.dxp.maginfrastructure.com/f/73114/80x50/591408d8a4/mag-blue.svg"
            height="30"
            alt="MAG"
            title="MAG"
          />
          <h1 className="title">Admin Portal</h1>
        </Link>

        <section className="float-right">
          <SignedIn>
            <ul className="navigation-list">
              <RedirectAuthenticated>
                <NavigationLink path="/redirects" text="Redirects" />
              </RedirectAuthenticated>
              <RedirectAuthenticated>
                <NavigationLink path="/ticker" text="Ticker" />
              </RedirectAuthenticated>
              <RecruitmentAuthenticated>
                <NavigationLink path="/jobs" text="Jobs" />
              </RecruitmentAuthenticated>
              <AdminAuthenticated>
                <NavigationLink path="/users" text="Users" />
              </AdminAuthenticated>
              <RecruitmentAuthenticated>
                <NavigationLink path="/manage" text="Manage" />
              </RecruitmentAuthenticated>
              <AdminAuthenticated>
                <NavigationLink path="/tenants" text="Tenants" />
              </AdminAuthenticated>
              <FulfilmentReadAuthenticated>
                <NavigationLink path="/fulfilment" text="Fulfilment" />
              </FulfilmentReadAuthenticated>
              <PassManagementAuthenticated>
                <NavigationLink path="/pass-management" text="Pass Management" />
              </PassManagementAuthenticated>
            </ul>
          </SignedIn>
          <SignedOut>
            <button className='button' onClick={() => Auth.federatedSignIn()}>{isLoading ? <><i className='icon-spinner rotate'></i> Signing In</> : "Sign In"}</button>
          </SignedOut>
        </section>
      </section>
    </nav>
  );
}

export default NavigationBar