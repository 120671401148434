export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export enum AuthGroup {
  Admin = 'admin',
  RecruitmentEditor = 'recruitment-editor',
  RecruitmentApproval = 'recruitment-approval',
  RedirectEditor = 'redirect-editor'
}

export enum AuthTenant {
  ManchesterAirport = 'MAN',
  EastMidlandsAirport = 'EMA',
  StanstedAirport = 'STN'
}

export const isGroupMember = (userGroups: AuthGroup[], groups: AuthGroup[]) => {
  return groups.some((group) => userGroups?.includes(group));
}

export const isAdmin = (userGroups: AuthGroup[]) => isGroupMember(userGroups, [AuthGroup.Admin]);
export const isRedirectEditor = (userGroups: AuthGroup[]) => isGroupMember(userGroups, [AuthGroup.Admin, AuthGroup.RedirectEditor]);
export const isRecruitmentEditor = (userGroups: AuthGroup[]) => isGroupMember(userGroups, [AuthGroup.Admin, AuthGroup.RecruitmentEditor, AuthGroup.RecruitmentApproval]);
export const isRecruitmentApproval = (userGroups: AuthGroup[]) => isGroupMember(userGroups, [AuthGroup.Admin, AuthGroup.RecruitmentApproval]);
export const isFulfilmentRead = (fulfilmentLevel: string = "") => ['manager-access', 'read'].includes(fulfilmentLevel);
export const isFulfilmentManager = (fulfilmentLevel: string = "") => ['manager-access'].includes(fulfilmentLevel);
export const isPassManagementEditor = (pmTenants: string[] = []) => pmTenants.some(ten => ["EMA", "MAN", "STN"].includes(ten))
export const isPassManagementWriter = (pmPerms: {[key:string]: string}, tenant: AuthTenant) => pmPerms[tenant] === "write";