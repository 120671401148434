import * as actions from './constants';

const initialState: UserState = {
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  users: [],
}

const reducer = (
  state: UserState = initialState,
  action: UserAction
): UserState => {
  switch (action.type) {
    case actions.SET_LOADING_USERS:
      return { ...state, isLoading: true, loadError: undefined }
    case actions.LOAD_USERS:
      if (action.response.error) {
        return { ...state, isLoading: false, loadError: action.response.error };
      }
      return {
        ...state,
        isLoading: false,
        users: action.response.users
      }
    case actions.SET_CREATING_USER:
      return { ...state, isCreating: true, createError: undefined }
    case actions.CREATE_USER:
      if (action.response.error) {
        return { ...state, isCreating: false, createError: action.response.error };
      }
      return {
        ...state,
        isCreating: false,
        users: state.users.concat(action.response.user),
      }
    case actions.SET_UPDATING_USER:
      return { ...state, isUpdating: true, updateError: undefined }
    case actions.UPDATE_USER:
      if (action.response.error) {
        return { ...state, isUpdating: false, updateError: action.response.error };
      }
      const user = action.response.user;
      return {
        ...state,
        isUpdating: false,
        users: state.users.map((u) => u.username === user.username ? user : u),
      }
    case actions.SET_DELETING_USER:
      return { ...state, isDeleting: true, deleteError: undefined }
    case actions.DELETE_USER:
      if (action.response.error) {
        return { ...state, isDeleting: false, deleteError: action.response.error };
      }
      return {
        ...state,
        isDeleting: false,
        users: state.users.filter((user) => user.username !== action.response.user.username),
      }
    case actions.RESET:
      return initialState;
    default: return state;
  }
}

export default reducer