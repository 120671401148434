import * as actions from './constants';

import { Theme } from '../../helpers/theme';

export function setTheme(theme: Theme) {
  const action: GlobalAction = {
    type: actions.SET_THEME,
    response: { theme }
  }

  return (dispatch: GlobalDispachType) => {
    dispatch(action);
  }
}