import AccordionItem from "./accordionItem";
type PropTypes = {
    data: {
        title: string,
        content: any,
    }[],
    header: string,
    onItemOpen?: () => void,
    width?: string
}
const Accordion: React.FC<PropTypes> = ({data, header, width}) => {
    return (
        <div>
          <h1>{header}</h1>
          <div className="accordion" style={{ width: width ?? undefined}}>
            {data.map(({ title, content }, i) => (
              <AccordionItem key={i} title={title} content={content} />
            ))}
          </div>
        </div>
      );
    
}
export default Accordion;