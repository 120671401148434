import Portal from './portal';
import { useRef } from 'react';

const Loader: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const nodeRef = useRef(null);
  return (
    <Portal wrapperId="react-portal-modal-container">
      {isLoading && <div className="loader" ref={nodeRef}></div>}
    </Portal>
  );
}

export default Loader