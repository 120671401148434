import * as Joi from 'joi';

import { AuthGroup, AuthTenant } from '../../helpers/authentication';

export const userSchema = Joi.object().keys({
  username: Joi.string().min(3).required(),
  email: Joi.string().email({ tlds: { allow: false } }).required(),
  phoneNumber: Joi.string().length(13).pattern(/^\+44[0-9]+$/).required(),
  authGroups: Joi.array().items(Joi.string().valid(...Object.values(AuthGroup))).required(),
  locations: Joi.array().items(Joi.string().valid(...Object.values(AuthTenant))).required(),
  tenants: Joi.array().items(Joi.string())
});

export const validateUser = (user: User) => userSchema.validate(user);