import * as actions from './constants';

import { Theme } from '../../helpers/theme';

const storedTheme = parseInt(localStorage.getItem('admin-portal-theme') || '');

const initialState: GlobalState = {
  theme: !isNaN(storedTheme) ? storedTheme : Theme.Light,
}

const getThemeClass = (theme: Theme) => {
  switch (theme) {
    case Theme.Light: return 'light';
    case Theme.Dark: return 'dark';
    default: throw new Error(`Invalid theme ${theme}`);
  }
}

const setThemeClass = (theme: Theme) => {
  const className: string = getThemeClass(theme);
  ['light', 'dark'].forEach((c) => document.body.classList.remove(c));
  document.body.classList.add(className);
}

setThemeClass(initialState.theme);

const reducer = (
  state: GlobalState = initialState,
  action: GlobalAction
): GlobalState => {
  switch (action.type) {
    case actions.SET_THEME: {
      setThemeClass(action.response.theme);
      localStorage.setItem('admin-portal-theme', action.response.theme);
      return {
        ...state,
        theme: action.response.theme
      }
    }
    default: return state;
  }
}

export default reducer