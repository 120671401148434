import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { PassFields } from './form/fields';
import Loader from '../global/loader';
import { passSchema } from '../../store/passManagement/schema';

type PropTypes = {
  airport: "MAN" | "STN" | "EMA",
  action: "Add" | "Edit",
  pass: Pass,
  setPass: (pass: Pass) => void,
  onSubmit: (pass: Pass) => void
}

export const ManagePassDetails: React.FC<PropTypes> = ({ onSubmit, airport, action, pass, setPass }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<any>();

  const { isLoading, createError, updateError } = useSelector(
    (state: MainState) => ({
      isLoading: state.passManagement.isCreating,
      createError: state.passManagement.createError,
      updateError: state.passManagement.updateError
    }),
    shallowEqual
  );

  useEffect(() => {
    const { error } = passSchema.validate(pass);
    setValidationError(error);
  }, [pass]);

  const onButtonClick = () => {
    setSubmitted(true);
    const { error } = passSchema.validate(pass);
    if (!error) {
      onSubmit(pass);
    }
  }

  return (
    <div className="modal-form">
      <h2>{action} Employee Details</h2>
      <Loader isLoading={isLoading} />
      {!isLoading &&
        <>
          {createError && <p className='error-message'>{createError?.message}</p>}
          {updateError && <p className='error-message'>{updateError?.message}</p>}
          <div className='modal-form-fields-scroll-wrapper'>
            <fieldset className="modal-form-fields">
              <PassFields pass={pass} updatePass={setPass} touchedOverride={submitted} />
            </fieldset>
          </div>

          <div className='modal-form-button-wrapper'>
            {submitted && validationError && <p className='error-message'>{validationError.message}</p>}
            <button disabled={validationError !== undefined} onClick={onButtonClick}>Save</button>
          </div>
        </>
      }
    </div>
  )
}