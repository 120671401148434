import React, { useCallback, useEffect, useState } from 'react'
import { createUser, deleteUser, loadUsers, resetUsersState, updateUser } from '../../store/users/actions';
import { defaultUser, locationText } from '../../helpers/factory';
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ConfirmModal } from '../global/confirmModal';
import { CreateUser } from './createUser';
import { Dispatch } from 'redux'
import { EditUser } from './editUser';
import Loader from '../global/loader';
import Modal from '../global/modal';
import { useCloseConfirmAfterLoad } from './hooks/useCloseConfirmAfterLoad';
import { useCloseCreateAfterLoad } from './hooks/useCloseCreateAfterLoad';
import { useCloseEditAfterLoad } from './hooks/useCloseEditAfterLoad';
import { loadTenants } from '../../store/tenants/actions';

export const UsersList: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isCreateOpen, setIsCreateOpen } = useCloseCreateAfterLoad();
  const { isEditOpen, setIsEditOpen } = useCloseEditAfterLoad();
  const { isConfirmOpen, setIsConfirmOpen, isDeleting, deleteError } = useCloseConfirmAfterLoad();
  const [editingUser, setEditingUser] = useState<User>(defaultUser());
  const [deletingUser, setDeletingUser] = useState<User>(defaultUser());

  useEffect(() => {
    dispatch(resetUsersState())
    dispatch(loadUsers());
    dispatch(loadTenants())
  }, [dispatch]);

  const { username, isLoading, loadError, users } = useSelector(
    (state: MainState) => ({
      username: state.authentication.username,
      isLoading: state.users.isLoading,
      loadError: state.users.loadError,
      users: state.users.users
    }),
    shallowEqual
  );

  const onOpenCreateUser = () => {
    setIsCreateOpen(true);
  }

  const onCreateUser = useCallback(
    (user: User) => dispatch(createUser(user)),
    [dispatch]
  );

  const onOpenEditUser = (user: User) => {
    setEditingUser(user);
    setIsEditOpen(true);
  }

  const onEditUser = useCallback(
    (user: User) => dispatch(updateUser(user)),
    [dispatch]
  );

  const onOpenDeleteUser = (user: User) => {
    setDeletingUser(user);
    setIsConfirmOpen(true);
  }

  const onDeleteUser = useCallback(
    () => dispatch(deleteUser(deletingUser)),
    [dispatch, deletingUser]
  );

  const orderedUsers = users.sort((a: User, b: User) => a.username < b.username ? -1 : a.username > b.username ? 1 : 0);

  return (
    <main>
      <h1>Users</h1>
      <Loader isLoading={isLoading} />
      <Modal handleClose={() => setIsCreateOpen(false)} isOpen={isCreateOpen}>
        <CreateUser onSubmit={onCreateUser} />
      </Modal>
      <Modal handleClose={() => setIsEditOpen(false)} isOpen={isEditOpen}>
        <EditUser isCreate={false} user={editingUser} updateUser={setEditingUser} onSubmit={onEditUser} />
      </Modal>
      <ConfirmModal
        message='Are you sure you want to delete this user?'
        isLoading={isDeleting}
        error={deleteError?.message}
        onConfirm={onDeleteUser}
        handleClose={() => setIsConfirmOpen(false)}
        isOpen={isConfirmOpen}
      />
      {!isLoading && loadError && <p className='error-message'>{loadError?.message}</p>}
      {!isLoading && !loadError &&
        <>
          <button onClick={() => onOpenCreateUser()}>Add User</button>
          <table className='react-table'>
            <thead>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <th>Roles</th>
                <th>Locations</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orderedUsers.map((user: User) => (
                <tr className="breakRows" key={user.username}>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.authGroups.map((group) => group.toString()).join(', ')}</td>
                  <td>{user.locations.map((location) => locationText(location)).join(', ')}</td>
                  <td>
                    <button title="Edit User" onClick={() => onOpenEditUser(user)} className="icon-pencil button-small"></button>
                    {username?.toLowerCase() !== user.username.toLowerCase() &&
                      <button title="Delete User" onClick={() => onOpenDeleteUser(user)} className="icon-cancel button-small"></button>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      }
    </main>
  );
}