import { useLayoutEffect, useState } from 'react';

import { createPortal } from 'react-dom';

const createWrapperAndAppendToBody = (wrapperId: string): HTMLDivElement => {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute("id", wrapperId);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
}

const Portal: React.FC<{ wrapperId: string}> = ({ children, wrapperId = "react-portal-wrapper" }) => {
    const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

    useLayoutEffect(() => {
        let element = document.getElementById(wrapperId);
        let systemCreated = false;

        if (!element) {
          systemCreated = true;
          element = createWrapperAndAppendToBody(wrapperId);
        }
        setWrapperElement(element);

        return () => {
          if (systemCreated && element?.parentNode) {
            element.parentNode.removeChild(element);
          }
        }
      }, [wrapperId]);

      if (wrapperElement === null) return null;

      return createPortal(children, wrapperElement);
}

export default Portal