import { ChangeEvent, useState } from 'react';

import { AnySchema } from 'joi';

type PropTypes = {
  type: string,
  label: string,
  value: any,
  schema: AnySchema,
  onChange: (value: any) => void,
  onSubmit?: () => void,
  touchedOverride?: boolean
}

export const Checkbox: React.FC<PropTypes> = ({ label, value, schema, onChange, touchedOverride }) => {
  const [touched, setTouched] = useState<boolean>(false);

  const { error } = schema.validate(value);
  const validationMessage = error?.message.replace('"value"', label);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    onChange(event)
  };


  const showError = value?.length > 0 && (touchedOverride || touched) && error;
  const checked = value && Boolean(value)
  const input = (<input 
    type="checkbox"
    value={value}
    onChange={onInputChange}
    className={showError ? 'error' : undefined}
    id={value}
    name={value}
    checked={checked}
  />)

  return (
    <>
      {showError && <p className="error-message">{validationMessage}</p>}
      <label htmlFor={value}>{label}</label>
      {input}
    </>
  )
};