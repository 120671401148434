import { Column, Row } from '../layout/grid';
import React, { useEffect, useState } from 'react';
import { passwordSchema, signInPasswordSchema, validateChangePassword } from '../../store/authentication/schema';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Dispatch } from 'redux';
import { Input } from '../form/validatedInput';
import { Link } from 'react-router-dom';
import Loader from '../global/loader';
import { changePassword } from '../../store/authentication/actions';

export const ChangePasswordForm: React.FunctionComponent<{}> = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isReset, setIsReset] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState<any>();

  const { isLoading, changePasswordError } = useSelector(
    (state: MainState) => ({
      isLoading: state.authentication.isChangingPassword,
      changePasswordError: state.authentication.changePasswordError
    }),
    shallowEqual
  );
  useEffect(() => {
    if (submitted && !isLoading && !changePasswordError) {
      setIsReset(true);
    }
  }, [submitted, isLoading, changePasswordError]);

  useEffect(() => {
    const result = validateChangePassword({ oldPassword, newPassword });
    setError(result.error);
  }, [oldPassword, newPassword]);

  const onChangePassword = () => {
    setSubmitted(true);
    if (!error) {
      dispatch(changePassword(oldPassword, newPassword));
    }
  }

  const passwordReset = (
    <>
      <h5>{`Password Changed`}</h5>
      <Link className="button" to="/">Home</Link>
    </>
  );

  const form = (
    <>
      <Loader isLoading={isLoading} />
      <section>
        <Input type='password' label='Old Password' autocomplete='current-password' value={oldPassword} schema={signInPasswordSchema} onChange={setOldPassword} touchedOverride={submitted} />
        <Input type='password' label='New Password' autocomplete='new-password' value={newPassword} schema={passwordSchema} onChange={setNewPassword} touchedOverride={submitted} />
        {changePasswordError && <label className="error-message">{changePasswordError.message}</label>}
        {submitted && error && <label className="error-message">{error.message}</label>}

        <Row>
          <Column offset={25} width={25}>
          <Link className='button' to="/">Cancel</Link>
          </Column>
          <Column width={25}>
            <button className='button' disabled={error !== undefined} onClick={onChangePassword}>Change Password</button>
          </Column>
        </Row>
      </section>
    </>
  );

  return isReset ? passwordReset : form;
}
