import * as Joi from 'joi';

export enum JobSector {
  Aviation = 'Aviation',
  Hospitality = 'Hospitality',
  Retail = 'Retail',
  AirsideOperation = 'Airside Operations',
  Logistics = 'Logistics'
}

export enum ApprovalStatus {
  AwaitingApproval,
  Approved,
  Rejected,
  Expired
};

export const jobSchema = Joi.object().keys({
  id: Joi.string().allow(null).allow('').optional(),
  title: Joi.string().required(),
  created: Joi.string(),
  createdBy: Joi.string(),
  updated: Joi.string(),
  updatedBy: Joi.string(),
  url: Joi.alternatives().try(Joi.string().email({ tlds: { allow: false }}), Joi.string().uri(),).required(),
  location: Joi.string().required(),
  segments: Joi.string().valid(...Object.values(JobSector)).required(),
  shifttype: Joi.string(),
  hours: Joi.string(),
  contract: Joi.string().required(),
  compensation: Joi.string().required(),
  description: Joi.string().required(),
  brand: Joi.string().required(),
  tenant: Joi.string(),
  expires: Joi.string().required(),
  terms: Joi.any().valid('true', true).required(),
  approvalStatus: Joi.number().valid(...Object.values(ApprovalStatus)),
  approvalNotes: Joi.string().allow(null).allow('').optional(),
  logo: Joi.string().allow(null).allow('').optional(),
  logoUrl: Joi.string().allow(null).allow('').optional()
});

export const validateJob = (job: Job) => jobSchema.validate(job);

export const approvalNotesSchema = Joi.string().min(3).required();