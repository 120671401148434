import * as Joi from 'joi';

import { statusCodeOptions } from '../../helpers/factory';

const pathRegex = new RegExp('(/|^/[\/A-Za-z0-9_.~-]+/)$');

export const redirectSchema = Joi.object().keys({
  key: Joi.string().pattern(pathRegex).required().error(new Error('Key must be a valid path starting and ending with /')),
  host: Joi.string()
  .pattern(/^(?!https:\/\/).*[^\/]$/)
  .messages({
    'string.pattern.base': 'Host value should not include https protocol or end in a trailing slash.',
  }),
  uri: Joi.string().pattern(pathRegex).required().error(new Error('Uri must be a valid path starting and ending with /')),
  status: Joi.number().valid(...statusCodeOptions.map((o) => o.value)).required()
});

export const validateRedirect = (redirect: Redirect) => redirectSchema.validate(redirect);

