import { shallowEqual, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export const useCloseExcelUploadAfterLoad = () => {
  const [isExcelUploadOpen, setIsExcelUploadOpen] = useState(false);

  const { isCreating, createError } = useSelector(
    (state: MainState) => ({
      isCreating: state.passManagement.isCreating,
      createError: state.passManagement.createError,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!isCreating && !createError) {
      setIsExcelUploadOpen(false);
    }
  }, [isCreating, createError]);

  return { isExcelUploadOpen, setIsExcelUploadOpen }
}