import { loadRedirects, resetRedirectsState } from '../../../store/redirects/actions';
import { shallowEqual, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { AuthTenant } from '../../../helpers/authentication';
import { Dispatch } from 'redux';

export const useLoadRedirects = (dispatch: Dispatch<any>) => {
  const locations: AuthTenant[] = useSelector(
    (state: MainState) => state.authentication.locations ?? [],
    shallowEqual
  );

  const [location, setLocation] = useState(locations[0]);

  useEffect(() => {
    dispatch(resetRedirectsState());
    if (location) {
      dispatch(loadRedirects(location));
    }
  }, [dispatch, location]);

  useEffect(() => {
    setLocation(locations[0]);
  }, [locations]);

  return { location, setLocation }
}