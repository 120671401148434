import { CognitoUser, CognitoUserPool, ISignUpResult } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify';

import config from '../helpers/config';
import jwtDecode from 'jwt-decode';

const poolData = {
  UserPoolId: config.userPoolId,
  ClientId: config.clientId,
}

const userPool: CognitoUserPool = new CognitoUserPool(poolData)

let currentUser: CognitoUser | any;

export async function getCurrentUser() {
  return currentUser || await Auth.currentAuthenticatedUser()
}

function getCognitoUser(username: string) {
  const userData = {
    Username: username,
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)

  return cognitoUser;
}

export async function getSession() {
  return await Auth.currentSession()
}

export async function signUpUserWithEmail(username: string, email: string, password: string): Promise<ISignUpResult> {
  const signUpParams = {
    username,
    password,
    attributes: {
      email
    }
  };
  return new Promise(function (resolve, reject) {
    Auth.signUp(signUpParams)
    .then(data => resolve(data))
    .catch(err => reject(err));
  })
}

export async function verifyCode(username: string, code: string) {
  const options = {
    forceAliasCreation: true
  }

  return new Promise(function (resolve, reject) {
    Auth.confirmSignUp(username, code, options)    
    .then(data => resolve(data))
    .catch(err => reject(err));
  })
}

export async function signOut() {
  return new Promise(function (resolve, reject) {
    Auth.signOut()
    .then(data => resolve(data))
    .catch(err => reject(err));
  })
}

export async function getAttributes() {
  return new Promise(function (resolve, reject) {
    if(!currentUser) {
      currentUser = getCurrentUser().then(user => user)
    }
    Auth.userAttributes(currentUser)
    .then(data => resolve(data))
    .catch(err => reject(err))
  })
}

export async function sendCode(username: string) {
  return new Promise(function (resolve, reject) {
    currentUser = getCurrentUser().then(user => user)

    if (!currentUser) {
      reject(`could not find ${username}`)
      return
    }

    Auth.forgotPassword(username)
    .then(data => resolve(data))
    .catch(err => reject(err))
  })
}

export async function forgotPassword(username: string, code: string, password: string) {
  return new Promise(function (resolve, reject) {
    currentUser = getCognitoUser(username)

    if (!currentUser) {
      reject(`could not find ${username}`)
      return
    }

    Auth.forgotPasswordSubmit(username, code, password)
    .then(data => resolve(data))
    .catch(err => reject(err))
  })
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return new Promise(async function(resolve, reject) {
    const user = await getCurrentUser()
    if(user) {
      Auth.changePassword(user, oldPassword, newPassword)
      .then(success => resolve(success))
      .catch(err => reject(err))
    }
  })
}

export async function completeNewPasswordChallenge(newPassword: string) {
  return new Promise(function (resolve, reject) {
    Auth.completeNewPassword(currentUser, newPassword)
    .then(data => resolve(data))
    .catch(err => reject(err))
  });
}

export async function getUserGroups(): Promise<string[]> {
  const session = await getSession();
  var sessionIdInfo = jwtDecode(session.getIdToken().getJwtToken()) || {} as any;
  return sessionIdInfo['cognito:groups'] || [];
}
