import * as Joi from 'joi';

export enum LogoList {
  Logo1 = 'Logo1',
  ManLogo = 'MAN Logo',
}

export const createDefaultLogo = (tenantId: string) => {
  return {
    tenantId,
    title: "Logo Name",
    value: "",
    size: 1,
    type: ".jpg",
};
  
}

export const manageSchema = Joi.object().keys({  
  tenantId: Joi.string().required(),
  chooseLogo: Joi.any(),
  value: Joi.any().custom((value, helpers) => {
    if (value instanceof File) {
      return value;
    } else {
      return helpers.error('any.invalid');
    }
  }, 'binary').required(),
  title: Joi.string().pattern(/^(?:(?!\.png|\.jpg|\.jpeg|\.svg|\?|!).)*$/).message("Please don't include special characters or file extensions."),
  size: Joi.number().less(3000000).message("The file size must not exceed 3mb"),
  type: Joi.string().valid(".jpg", ".jpeg", ".png", ".svg"),
});

export const validateLogo = (logo: LogoDetails) => manageSchema.validate(logo);

export const approvalNotesSchema = Joi.string().min(3).required();