import * as Joi from 'joi';

import { AuthTenant } from '../../helpers/authentication';

export const tenantSchema = Joi.object().keys({
  tenantId: Joi.string().optional(),
  name: Joi.string().required(),
  description: Joi.string(),
  location: Joi.string().valid(...Object.values(AuthTenant)),
  contact: Joi.string().required(),
  subTenants: Joi.array().items(Joi.string().allow(null)).sparse(true),
  logoTitles: Joi.array().items(Joi.string()).allow(null).sparse(true)
});

export const validateTenant = (tenant: Tenant) => tenantSchema.validate(tenant);