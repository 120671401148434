import { Link } from 'react-router-dom';

const NavigationLink: React.FC<{ path: string; text: string; }> = ({ path, text }) => {
  return (
    <li className="navigation-item">
      <Link className="navigation-link" to={path}>{text}</Link>
    </li>
  );
}

export default NavigationLink