import { Select as ValidatedSelect } from '../../form/validatedSelect';
import { jobSchema } from '../../../store/jobs/schema';

type PropTypes = {
  propName: string,
  options: SelectOption[],
  label: string,
  job: Job,
  updateJob: (job: Job) => void,
  touchedOverride?: boolean
}

export const Select: React.FC<PropTypes> = ({ propName, options, label, job, updateJob, touchedOverride }) => {

  const onChange = (value: any) => updateJob({ ...job, [propName]: value });
  const schema = jobSchema.extract(propName);

  return (
    <ValidatedSelect
      label={label}
      options={options}
      value={(job as any)[propName]}
      schema={schema}
      onChange={onChange}
      touchedOverride={touchedOverride}
    />
  )
};