import Select, { MultiValue } from 'react-select';

import { AnySchema } from 'joi';
import { useState } from 'react';

type PropTypes = {
  disabled?: boolean,
  label: string,
  options: MultiValue<any>,
  selectedOptions: MultiValue<any>,
  schema: AnySchema,
  onChange: (value: any) => void,
  touchedOverride?: boolean
}

export const MultiSelect: React.FC<PropTypes> = ({ disabled = false, label, options, selectedOptions, schema, onChange, touchedOverride }) => {
  const [touched, setTouched] = useState<boolean>(false);

  const { error } = schema.validate(selectedOptions.map((v) => v.value));
  const validationMessage = error?.message.replace('"value"', label);

  const onSelectChange = (values: MultiValue<any>) => {
    setTouched(true);
    onChange(values)
  };

  return (
    <>
      <label>{label}</label>
      {(touchedOverride || touched) && error && <p className="error-message">{validationMessage}</p>}
      <Select
        menuShouldScrollIntoView={true}
        options={options}
        defaultValue={selectedOptions}
        onChange={onSelectChange}
        className="multi-select-container"
        classNamePrefix="multi-select"
        isDisabled={disabled}
        isMulti
      />
    </>
  )
};