import { shallowEqual, useSelector } from "react-redux";

type PropTypes = {
  fileDetails: File,
  setFile: (file: File | undefined) => void,
  onSubmit: any
}

export const UploadFile: React.FC<PropTypes> = ({ fileDetails, setFile, onSubmit }) => {
  const { isCreating, createError } = useSelector(
    (state: MainState) => ({
      isCreating: state.passManagement.isCreating,
      createError: state.passManagement.createFileError,
    }),
    shallowEqual
  );
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  if(event.target.files?.[0]) {
    const file = event.target.files[0]
    setFile(file);
  } else {
    setFile(undefined)
  }
};


return (
  <div className="modal-form">
    <h2>Upload Employee Details</h2>
    <fieldset className="modal-form-fields">
    <label>File Upload</label>
      <input className="uploadImageButton" type="file" id="myFile" name="file" accept=".xls,.xlsx,.csv" onChange={onFileChange}></input>
      </fieldset>
      <div className='modal-form-button-wrapper'>
      {createError && <p className='error-message'>We could not upload your file. Please contact your administrator.</p>}
        <button onClick={() => onSubmit(fileDetails)}>{isCreating ? "Sending...": "Upload File"}</button>
      </div>
    </div>
  ); 
};