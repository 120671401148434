export const SET_LOADING_LOGOS = 'LOGOS_SET_LOADING_LOGOS';
export const LOAD_LOGOS = 'LOGOS_LOAD_LOGOS';
export const SET_CREATING_LOGO = 'LOGOS_SET_CREATING_LOGO';
export const CREATE_LOGO = 'LOGOS_CREATE_LOGO';
export const CREATE_ERROR = "ERROR_CREATING_LOGO";
export const SET_UPDATING_LOGO = 'LOGOS_SET_UPDATING_LOGO';
export const UPDATE_LOGO = 'LOGOS_UPDATE_LOGO';
export const SET_DELETING_LOGO = 'LOGOS_SET_DELETING_LOGO';
export const DELETE_LOGO = 'LOGOS_DELETE_LOGO';
export const RESET = 'LOGOS_RESET';
export const ADD_LOGO = 'ADD_LOGO';
export const CREATE_LOGO_ERROR = 'CREATE_LOGO_ERROR';
export const RESET_LOGOS = 'RESET_LOGOS';
export const LOAD_ALLOWED_TENANTS = "LOAD_ALLOWED_TENANTS";
export const SET_LOADING_TENANTS = "SET_LOADING_TENANTS"
