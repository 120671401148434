import React, { useCallback, useEffect, useState } from 'react'
import { createJob, deleteJob, loadJobs, updateJob, updateJobApprovalStatus } from '../../store/jobs/actions';
import { defaultJob, statusText } from '../../helpers/factory';
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ApprovalStatus } from '../../store/jobs/schema';
import { ConfirmModal } from '../global/confirmModal';
import { CreateJob } from './createJob';
import { Dispatch } from 'redux'
import { EditJob } from './editJob';
import Loader from '../global/loader';
import Modal from '../global/modal';
import { LocationSelect } from '../form/locationSelect';
import { useCloseConfirmAfterLoad } from './hooks/useCloseConfirmAfterLoad';
import { useCloseCreateAfterLoad } from './hooks/useCloseCreateAfterLoad';
import { useCloseEditAfterLoad } from './hooks/useCloseEditAfterLoad';
import { useLoadJobs } from './hooks/useLoadJobs';
import { useSearchParams } from 'react-router-dom';
import { isRecruitmentApproval } from '../../helpers/authentication';
import { isSuccessFactors } from '../../helpers/jobs';

const rowClass = (job: Job) => {
  switch (job.approvalStatus) {
    case ApprovalStatus.AwaitingApproval: return 'awaiting';
    case ApprovalStatus.Rejected: return 'rejected';
    case ApprovalStatus.Expired: return 'expired';
    default: return '';
  }
}

enum JobFilter {
  None = 'None',
  AwaitingApproval = 'Awaiting',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Expired = 'Expired'
}

export const JobsList: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { location, setLocation } = useLoadJobs(dispatch);
  const [editingJob, setEditingJob] = useState<Job>(defaultJob());
  const { isCreateOpen, setIsCreateOpen } = useCloseCreateAfterLoad();
  const { isEditOpen, setIsEditOpen } = useCloseEditAfterLoad();
  const { isConfirmOpen, setIsConfirmOpen, isDeleting, deleteError } = useCloseConfirmAfterLoad();
  const [deletingJob, setDeletingJob] = useState<Job>(defaultJob());
  const [filter, setFilter] = useState<JobFilter>(JobFilter.None);
  const [searchParams] = useSearchParams();

  const { username, roles, isLoading, loadError, jobs } = useSelector(
    (state: MainState) => ({
      roles: state.authentication.authGroups,
      username: state.authentication.username,
      isLoading: state.jobs.isLoading,
      loadError: state.jobs.loadError,
      jobs: state.jobs.jobs
    }),
    shallowEqual
  );


  useEffect(() => {
    const searchFilter = searchParams.get('filter');
    if (searchFilter) {
      setFilter(searchFilter as JobFilter);
    }
  }, [searchParams])

  const orderedJobs = jobs.sort((a: Job, b: Job) => a.title < b.title ? -1 : a.title > b.title ? 1 : 0);
  const filteredJobs = orderedJobs.filter((job: Job) => {
    if(job.tenant !== location) return false
    switch (filter) {
      case JobFilter.None: return true;
      case JobFilter.Approved: return job.approvalStatus === ApprovalStatus.Approved;
      case JobFilter.AwaitingApproval: return job.approvalStatus === ApprovalStatus.AwaitingApproval;
      case JobFilter.Rejected: return job.approvalStatus === ApprovalStatus.Rejected;
      case JobFilter.Expired: return job.approvalStatus === ApprovalStatus.Expired
      default: throw new Error(`Unexpected filter ${filter}`);
    }
  });

  const onRefresh = () => dispatch(loadJobs(location, username || ""))

  const onOpenCreateJob = () => {
    setIsCreateOpen(true);
  }

  const onCreateJob = useCallback(
    (job: Job) => dispatch(createJob(job)),
    [dispatch, location]
  );

  const onOpenEditJob = (job: Job) => {
    setEditingJob(job);
    setIsEditOpen(true);
  }

  const onEditJob = useCallback(
    (job: Job) => dispatch(updateJob(location, job)),
    [dispatch, location]
  );

  const onOpenDeleteJob = (job: Job) => {
    setDeletingJob(job);
    setIsConfirmOpen(true);
  }

  const onDeleteJob = useCallback(
    () => dispatch(deleteJob(location, deletingJob)),
    [dispatch, location, deletingJob]
  );

  const onUpdateJobApprovalStatus = useCallback(
    (job: Job, approvalStatus: ApprovalStatus, approvalNotes?: string) => dispatch(updateJobApprovalStatus(location, job.id as string, approvalStatus, job.createdBy, approvalNotes)),
    [dispatch, location]
  )

  const filterRadio = (option: JobFilter, label: string) => (
    <>
      <input type={'radio'} name={'jobs-filter'} checked={filter === option} value={option} onChange={(e) => setFilter(option)} />
      <label>{label}</label>
    </>
  );

  const deleteJobTitle = (job: Job): string => {
    const successFactorsJobTitle = "Cannot delete SuccessFactors roles"
    const deleteJob = "Delete Job"
    return isSuccessFactors(job) ? 
      successFactorsJobTitle : 
      deleteJob
  }

  return (
    <main>
      <h1>Jobs</h1>
      <LocationSelect value={location} onChange={setLocation} />
      <Loader isLoading={isLoading} />
      <Modal handleClose={() => setIsCreateOpen(false)} isOpen={isCreateOpen}>
        <CreateJob onSubmit={onCreateJob} />
      </Modal>
      <Modal handleClose={() => setIsEditOpen(false)} isOpen={isEditOpen}>
        <EditJob job={editingJob} updateJob={setEditingJob} onSubmit={onEditJob} onUpdateApprovalStatus={onUpdateJobApprovalStatus} />
      </Modal>
      <ConfirmModal
        message='Are you sure you want to delete this job?'
        isLoading={isDeleting}
        error={deleteError?.message}
        onConfirm={onDeleteJob}
        handleClose={() => setIsConfirmOpen(false)}
        isOpen={isConfirmOpen}
      />
      {!isLoading && loadError && <p className='error-message'>{loadError?.message}</p>}
      {!isLoading && !loadError &&
        <>
          <button style={{width: "100%"}}onClick={() => onOpenCreateJob()}>Add Job</button>
          <button style={{width: "100%"}} onClick={() => onRefresh()}>Refresh</button>          
          <fieldset className='filters'>
            {filterRadio(JobFilter.None, 'All')}
            {filterRadio(JobFilter.Approved, 'Approved')}
            {filterRadio(JobFilter.AwaitingApproval, 'Awaiting Approval')}
            {filterRadio(JobFilter.Rejected, 'Rejected')}
            {filterRadio(JobFilter.Expired, 'Expired')}
          </fieldset>
          <table className="react-table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Location</th>
                <th>Status</th>
                <th>Created By</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredJobs.map((job: Job) => (
                <tr key={job.id} className={rowClass(job)}>
                  <td>{job.title}</td>
                  <td>{job.location}</td>
                  <td>{statusText(job.approvalStatus)}</td>
                  <td>
                    {job.createdBy}
                  </td>
                  <td>
                    <button title="Edit Job" onClick={() => onOpenEditJob(job)} className={`button-small ${isSuccessFactors(job) ? "icon-info" : "icon-pencil"}`}></button>
                    {
                      (isRecruitmentApproval(roles) || job.createdBy?.toLowerCase() === username?.toLowerCase()) && 
                      <button title={deleteJobTitle(job)} onClick={() => onOpenDeleteJob(job)} className="icon-cancel button-small" disabled={isSuccessFactors(job)}></button>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      }
    </main>
  );
}