export const SET_LOADING_JOBS = 'JOBS_SET_LOADING_JOBS';
export const LOAD_JOBS = 'JOBS_LOAD_JOBS';
export const SET_CREATING_JOB = 'JOBS_SET_CREATING_JOB';
export const CREATE_JOB = 'JOBS_CREATE_JOB';
export const SET_UPDATING_JOB = 'JOBS_SET_UPDATING_JOB';
export const UPDATE_JOB = 'JOBS_UPDATE_JOB';
export const SET_DELETING_JOB = 'JOBS_SET_DELETING_JOB';
export const DELETE_JOB = 'JOBS_DELETE_JOB';
export const SET_UPDATING_JOB_APPROVAL_STATUS = 'JOBS_SET_UPDATING_JOB_APPROVAL_STATUS';
export const UPDATE_JOB_APPROVAL_STATUS = 'JOBS_UPDATE_JOB_APPROVAL_STATUS';
export const RESET = 'JOBS_RESET';
export const UPDATE_ID = "UPDATE_ID";
export const EDITED = "-edited-pending";
