import { shallowEqual, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export const useCloseCreateAfterLoad = () => {
  const [isCreateOpen, setIsCreateOpen] = useState(false);

  const { isCreating, createError } = useSelector(
    (state: MainState) => ({
      isCreating: state.jobs.isCreating,
      createError: state.jobs.createError,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!isCreating && !createError) {
      setIsCreateOpen(false);
    }
  }, [isCreating, createError]);

  return { isCreateOpen, setIsCreateOpen }
}