import { AdminAuthenticated } from '../authentication/authenticated';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { useEffect, useState } from 'react';
import { AuthStatus, isRecruitmentApproval, isRecruitmentEditor } from '../../helpers/authentication';
import { loadNotificationsAction } from '../../store/authentication/actions';

const Notifications: React.FC = () => {
  const [open, setOpen] = useState(false);
  const dispatch: Dispatch<any> = useDispatch();

  const { roles, awaiting, rejected, isLoading, loadError, isSignedIn } = useSelector(
    (state: MainState) => ({
      isSignedIn: state.authentication.authStatus === AuthStatus.SignedIn,
      roles: state.authentication.authGroups, 
      awaiting: state.authentication.notifications.awaiting,
      rejected: state.authentication.notifications.rejected,
      isLoading: state.authentication.isLoadingNotifications,
      loadError: state.authentication.loadNotificationError
    }),
    shallowEqual
  );
  let count = 0;
  if(isRecruitmentEditor(roles)) {
    count += rejected;
  }

  if(isRecruitmentApproval(roles)) {
    count += awaiting;
  }
  
  useEffect(() => {
    if(isSignedIn) {
      const interval = setInterval(() => {
        loadNotificationsAction(dispatch);
      }, 30000); // every 30 seconds
      return () => clearInterval(interval);
    }
  }, []);

  return (
    <AdminAuthenticated>
      <div className="notification-box float-right">
        <label onClick={() => setOpen(!open)}>
          <i className='icon-bell' />
          {!isLoading && count > 0 && <span className='badge'>{count}</span>}
          {isLoading && <i className='icon-spinner rotate'></i>}
          {loadError}
        </label>
        <ul className={open ? 'open' : ''}>
          <AdminAuthenticated>
            <li><Link to="/jobs?filter=Awaiting">{awaiting} job(s) are awaiting your approval</Link></li>
          </AdminAuthenticated>
          <li><Link to="/jobs?filter=Rejected">{rejected} job(s) you created have been rejected</Link></li>
        </ul>
      </div>
    </AdminAuthenticated>
  );
}

export default Notifications