
import { Input } from '../../form/validatedInput';
import { passSchema } from '../../../store/passManagement/schema';
import { shallowEqual, useSelector } from 'react-redux';
import { useState } from 'react';
import Loader from '../../global/loader';
import { UploadFile } from './uploadFile';

type PropTypes = {
  pass: Pass,
  updatePass: (pass: Pass) => void,
  touchedOverride?: boolean;

}

export const PassFields: React.FC<PropTypes> = ({ pass, updatePass, touchedOverride }) => {

  const [submitted, setSubmitted] = useState<boolean>(false);
  const { isLoading } = useSelector(
    (state: MainState) => ({
      isLoading: state.passManagement.isCreating
    }),
    shallowEqual
  );

  return (
    <div className="modal-form">
      <Loader isLoading={isLoading} />
      {!isLoading &&
        <>
          <div className='modal-form-fields-scroll-wrapper'>
            <fieldset className="modal-form-fields">
              <Input
                type='text'
                label='Employee Name'
                value={pass.employeename}
                schema={passSchema.extract('employeename')}
                onChange={(value) => updatePass({ ...pass, employeename: value })}
                touchedOverride={submitted}
              />
              <Input
                type='text'
                label='Employee ID'
                value={pass.employeeid}
                schema={passSchema.extract('employeeid')}
                onChange={(value) => updatePass({ ...pass, employeeid: value })}
                touchedOverride={submitted}
              />
              <Input
                type='text'
                label='Role'
                value={pass.role}
                schema={passSchema.extract('role')}
                onChange={(value) => updatePass({ ...pass, role: value })}
                touchedOverride={submitted}
              />
              <Input
                type='text'
                label='Pass ID'
                value={pass.passid}
                schema={passSchema.extract('passid')}
                onChange={(value) => updatePass({ ...pass, passid: value })}
                touchedOverride={submitted}
              />
              {/* <Input
                type='text'
                label='Airport'
                disabled={true}
                value={pass.airport}
                schema={passSchema.extract('airport')}
                onChange={(v) => { }}
                touchedOverride={submitted}
              /> */}
            </fieldset>
          </div>
        </>
      }
    </div>
  )
};

