import { JobsList } from '../components/jobs/jobsList';
import NavigationBar from '../components/navigation/navigationBar';

const Jobs: React.FC = () => {
  return (
    <div className="wrapper">
      <NavigationBar />
      <section className="container">
        <JobsList />
      </section>
    </div>
  );
}

export default Jobs