import NavigationBar from '../components/navigation/navigationBar';
import { SignedOut } from '../components/authentication/authenticated';
import { Auth } from 'aws-amplify'

const Home: React.FC = () => {
  return (
    <div className="wrapper">
      <NavigationBar />

      <header className="header" id="home">
        <section className="container">
          <img
            className="img"
            src="https://assets.live.dxp.maginfrastructure.com/f/73114/80x50/591408d8a4/mag-blue.svg"
            alt="MAG"
            title="MAG"
          />
          <h1 className="title">MAG Admin Portal</h1>
          <p className="description">Manage DXP</p>
          <SignedOut>
          <button className='button' onClick={() => Auth.federatedSignIn()}>Sign In</button>
          </SignedOut>
        </section>
      </header>

      <section className="container">
        <h5 className="title">Need another account for your company?</h5>
        <p>Contact your administrator and ask for a login.</p>
      </section>
    </div>
  );
}

export default Home