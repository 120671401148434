import awsAuth from "../.aws-config/awsauth.json";
import awsConfig from "../.aws-config/awsconfig.json"
const getEnvironment = () => {
    switch (window.location.hostname) {
        case 'admin.mag-dxp.com': return 'live';
        case 'localhost': return 'local';
        default: return 'test';
    }
}

const environment = getEnvironment();

const config = JSON.parse(process.env.REACT_APP_CONFIG || '{}')[environment];

const configuration = {
    awsAuth: awsAuth[environment],
    awsConfig: awsConfig[environment],
    userPoolId: config?.UserPoolId || process.env.REACT_APP_USERPOOL_ID,
    clientId: config?.ClientId || process.env.REACT_APP_CLIENT_ID,
    apiUrl: config?.ApiUrl || process.env.REACT_APP_API_URL,
    logoBucketName: `adminportal-${environment}-brand-logos`
};

export default configuration;