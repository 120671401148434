import * as actions from './constants';

const initialState: PassState = {
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  created: false,
  passes: [],
  searchResults: [],
  toasts: []
}

const reducer = (
  state: PassState = initialState,
  action: PassAction
): PassState => {
  const addPass = (pass: Pass) => {
    return state.passes.concat(pass)
  }

  const updatePass = (pass: Pass, type: "passes" | "searchResults") => {
    return state[type].map((p: Pass) => p.id === pass.id ? pass : p)
  }

  switch (action.type) {
    case actions.SET_CREATING_FILE:
      return { ...state, isCreating: true, loadError: undefined }
    case actions.CREATE_FILE:
      return { ...state, created: true, loadError: undefined, isCreating: false }
    case actions.SET_LOADING_PASSES:
      return { ...state, isLoading: true, loadError: undefined }
    case actions.SET_SEARCHING_PASSES:
      return { ...state, isLoading: true, loadError: undefined }
    case actions.SET_SEARCH_RESULTS:
      return { ...state, searchResults: action.response.passes, isLoading: false }
    case actions.LOAD_PASSES:
      if (action.response.error) {
        return { ...state, isLoading: false, loadError: action.response.error };
      }
      return {
        ...state,
        isLoading: false,
        passes: action.response.passes,
      }
    case actions.REMOVE_TOAST: {
      return {
        ...state,
        toasts: state.toasts.filter((toast) => {
          return !(action.response.data.type === toast.type && action.response.data.message === toast.message)
        })
      }
    }
    case actions.ADD_TOAST: {
      return {
        ...state,
        toasts: state.toasts.concat(action.response.data)
      }
    }
    case actions.SET_CREATING_PASS:
      return { ...state, isCreating: true, createError: undefined }
    case actions.CREATE_PASS:
      if (action.response.error) {
        return { ...state, isCreating: false, createError: action.response.error };
      }
      return {
        ...state,
        isCreating: false,
        passes: addPass(action.response.pass)
      }
    case actions.SET_UPDATING_PASS:
      return { ...state, isLoading: true, isUpdating: true, updateError: undefined }
    case actions.UPDATE_PASS:
      if (action.response.error) {
        return { ...state, isLoading: false, isUpdating: false, updateError: action.response.error };
      }
      return {
        ...state,
        isUpdating: false,
        isLoading: false,
        [action.response.type]: updatePass(action.response.pass, action.response.type),
      }
    case actions.SET_DELETING_PASS:
      return { ...state, isDeleting: true, deleteError: undefined }
    case actions.DELETE_PASS:
      const type = action.response.type as "searchResults" | "passes"
      if (action.response.error) {
        return { ...state, isDeleting: false, deleteError: action.response.error };
      }
      return {
        ...state,
        isDeleting: false,
        [type]: state[type].filter((pass) => pass.id !== action.response.pass.id),
      }
    case actions.CREATE_FILE_ERROR:
      return {
        ...state,
        isCreating: false,
        createFileError: action.response.error
      }
    case actions.RESET:
      return {
        ...state,
        isCreating: false,
        isLoading: false,
        createError: undefined,
        updateError: undefined,
        createFileError: undefined,
      };
    default: return state;
  }
}

export default reducer