import { useEffect, useState } from 'react';
import { request } from '../../../helpers/request';
import config from '../../../helpers/config';
import { AuthTenant } from '../../../helpers/authentication';

const useBulkUploadRecords = (location: AuthTenant) => {
  const [items, setItems] = useState<BulkUploadRecord[]>([]);
  const [loading, setLoading] = useState(false)

  const fetchItems = async () => {
    setLoading(true)
    try {
      const records: BulkUploadRecord[] = await request("GET", `${config.apiUrl}/pass-management/audit-items?tenant=${location}&pageSize=10`);

      if(records.length) {
        setItems(records);
      }
    } catch (error) {
      console.error('Error fetching items:', error);
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const refresh = () => {
    setItems([])
    fetchItems()
  }

  return { items, resultsLoading: loading, refresh };
};

export default useBulkUploadRecords;
