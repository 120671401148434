import * as actions from './constants';

import { AuthTenant } from '../../helpers/authentication';
import config from '../../helpers/config';
import { request } from '../../helpers/request';

export function resetRedirectsState() {
  return (dispatch: JobDispatchType) => {
    dispatch({ type: actions.RESET });
  }
}

export function loadRedirects(tenant: string) {
  const action: RedirectAction = { type: actions.LOAD_REDIRECTS };

  return async (dispatch: JobDispatchType) => {
    try {
      dispatch({ type: actions.SET_LOADING_REDIRECTS });
      const data = await request('GET', `${config.apiUrl}/redirects?tenant=${tenant}`);
      dispatch({ ...action, response: { redirects: data.redirects } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function createRedirect(tenant: AuthTenant, redirect: Redirect) {
  const action: RedirectAction = { type: actions.CREATE_REDIRECT };

  return async (dispatch: RedirectDispatchType) => {
    try {
      dispatch({ type: actions.SET_CREATING_REDIRECT });
      const body = { redirect };
      const data = await request('POST', `${config.apiUrl}/redirects?tenant=${tenant}`, body);
      dispatch({ ...action, response: { redirect: data.redirect } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function updateRedirect(tenant: AuthTenant, redirect: Redirect) {
  const action: RedirectAction = { type: actions.UPDATE_REDIRECT };

  return async (dispatch: RedirectDispatchType) => {
    try {
      dispatch({ type: actions.SET_UPDATING_REDIRECT });
      const body = { redirect };
      const data = await request('PUT', `${config.apiUrl}/redirects?tenant=${tenant}`, body);
      dispatch({ ...action, response: { redirect: data.redirect } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function deleteRedirect(tenant: AuthTenant, redirect: Redirect) {
  const action: RedirectAction = { type: actions.DELETE_REDIRECT };

  return async (dispatch: RedirectDispatchType) => {
    try {
      dispatch({ type: actions.SET_DELETING_REDIRECT });
      await request('DELETE', `${config.apiUrl}/redirects/${encodeURIComponent(redirect.key)}?tenant=${tenant}`);
      dispatch({ ...action, response: { redirect } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}