import { useState } from "react";
type PropTypes = {
    title: string, 
    content: any,
}
const AccordionItem: React.FC<PropTypes> = ({title, content}) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  );
}
export default AccordionItem;