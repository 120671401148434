import { PassList } from '../components/passManagement/passList';
import NavigationBar from '../components/navigation/navigationBar';
import ToastList from '../components/global/toastList';

const PassManagement: React.FC = () => {
  return (
    <div className="wrapper">
      <NavigationBar />
      <section className="container">
      <ToastList />
        <PassList />
      </section>
    </div>
  );
}

export default PassManagement