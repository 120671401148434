import { Dispatch, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { AuthStatus } from '../../helpers/authentication';
import { SignedIn } from '../authentication/authenticated';
import ThemeToggle from '../global/themeToggle';
import { signOut } from '../../store/authentication/actions';
import { Link, useNavigate } from 'react-router-dom';

const UserBox: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [open, setOpen] = useState(false);

  const { username, authStatus } = useSelector(
    (state: MainState) => ({
      authStatus: state.authentication.authStatus,
      username: state.authentication.username ?? '',
    }),
    shallowEqual
  );

  useEffect(() => {
    if (authStatus === AuthStatus.SignedOut) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, [authStatus])

  const navigate = useNavigate();

  const signOutClicked = async () => {
    dispatch(signOut());
    navigate('/');
  }

  return (
    <div className="user-box float-right">
      <label onClick={() => setOpen(!open)}>{username.replace("azuread_", "")}<i className="icon-down" /></label>
      <ul className={open ? 'open' : ''}>
        <li><label>Theme <ThemeToggle /></label></li>
        <li><label><a href="mailto:magdigital-dev@magairports.com">Contact Us</a></label></li>
        <SignedIn>
          <li><label onClick={signOutClicked}>Sign Out</label></li>
          <li><label><Link to="/changepassword">Change Password</Link></label></li>
        </SignedIn>
      </ul>
    </div>
  );
}

export default UserBox