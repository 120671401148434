import { Column, ColumnAlign, Container, Row } from '../layout/grid';

import Loader from './loader';
import Modal from './modal';
import React from 'react';

type PropTypes = {
  message: string,
  isOpen: boolean,
  error?: string,
  isLoading?: boolean;
  onConfirm: () => void,
  handleClose: () => void
}

export const ConfirmModal: React.FC<PropTypes> = ({ children, message, isOpen, error, isLoading = false, onConfirm, handleClose }) => {
  return (
    <Modal handleClose={handleClose} isOpen={isOpen}>
      <Container>
        <Loader isLoading={isLoading} />
        {!isLoading &&
          <>
            <Row>
              <Column width={50} offset={25} align={ColumnAlign.Center}>
                {error && <p className='error-message'>{error}</p>}
                <p>{message}</p>
              </Column>
            </Row>
            {children && <Row>{children}</Row>}
            <Row>
              <Column width={50} offset={25} align={ColumnAlign.Center}>
                <button onClick={onConfirm}>Continue</button>
              </Column>
            </Row>
          </>
        }
      </Container>
    </Modal>
  )
}