export const RESET = 'AUTH_RESET';
export const SIGN_IN_WITH_LOCAL_STORAGE = 'AUTH_SIGN_IN_WITH_LOCAL_STORAGE';
export const SET_IS_SIGNING_IN = 'AUTH_SET_IS_SIGNING_IN';
export const SET_REQUIRES_PASSWORD_RESET = 'AUTH_SET_REQUIRES_PASSWORD_RESET';
export const SIGN_IN_WITH_EMAIL = 'AUTH_SIGN_IN_WITH_EMAIL';
export const SIGN_OUT = 'AUTH_SIGN_OUT';
export const SET_IS_SIGNING_UP = 'AUTH_SET_IS_SIGNING_UP';
export const SIGN_UP_WITH_EMAIL = 'AUTH_SIGN_UP_WITH_EMAIL';
export const SET_IS_VERIFYING_CODE = 'AUTH_SET_IS_VERIFYING_CODE';
export const VERIFY_CODE = 'AUTH_VERIFY_CODE';
export const SET_SUBMITTING_FORGOT_PASSWORD = 'AUTH_SET_SUBMITTING_FORGOT_PASSWORD';
export const FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const SET_IS_CHANGING_PASSWORD = 'AUTH_SET_IS_CHANGING_PASSWORD';
export const CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const COMPLETE_NEW_PASSWORD_CHALLENGE = 'AUTH_COMPLETE_NEW_PASSWORD_CHALLENGE';
export const SET_IS_SENDING_CODE = 'AUTH_SET_IS_SENDING_CODE';
export const SEND_CODE = 'AUTH_SEND_CODE';
export const SET_IS_LOADING_NOTIFICATIONS = 'AUTH_SET_IS_LOADING_NOTIFICATIONS';
export const LOAD_NOTIFICATIONS = 'AUTH_LOAD_NOTIFICATIONS';
export const MFA_VERIFICATION_REQUIRED = 'MFA_VERIFICATION_REQUIRED';
export const MFA_VERIFICATION_FAILED = 'MFA_VERIFICATION_FAILED';
export const VERIFIED_MFA_CODE = "VERIFY_MFA_CODE";