import { ChangeEvent, useState } from 'react';

import { AnySchema } from 'joi';

type PropTypes = {
  disabled?: boolean,
  label: string,
  options: SelectOption[],
  value: string,
  schema: AnySchema,
  onChange: (value: any) => void,
  touchedOverride?: boolean
}

export const Select: React.FC<PropTypes> = ({ disabled = false, label, options, value, schema, onChange, touchedOverride }) => {
  const [touched, setTouched] = useState<boolean>(false);

  const { error } = schema.validate(value);
  const validationMessage = error?.message.replace('"value"', label);

  const onSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setTouched(true);
    onChange(event.currentTarget.value)
  };

  return (
    <>
      <label>{label}</label>
      {(touchedOverride || touched) && error && <p className="error-message">{validationMessage}</p>}
      <select value={value} onChange={onSelectChange} disabled={disabled}>
        {options.map((o) => { return (<option key={o.value} value={o.value}>{o.label}</option>); })}
      </select>
    </>
  )
};