import { shallowEqual, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export const useCloseEditAfterLoad = () => {
  const [isEditOpen, setIsEditOpen] = useState(false);

  const { isUpdating, updateError } = useSelector(
    (state: MainState) => ({
      isUpdating: state.redirects.isUpdating,
      updateError: state.redirects.updateError,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!isUpdating && !updateError) {
      setIsEditOpen(false);
    }
  }, [isUpdating, updateError]);

  return { isEditOpen, setIsEditOpen }
}