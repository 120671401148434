import BulkAuditTable from '../../components/passManagement/auditTable';
import NavigationBar from '../../components/navigation/navigationBar';

const BulkUploadRecord: React.FC = () => {
  return (
    <div className="wrapper">
      <NavigationBar />
      <section className="container">
        <BulkAuditTable />
      </section>
    </div>
  );
}

export default BulkUploadRecord