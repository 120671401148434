import { AuthGroup, isAdmin } from '../../helpers/authentication';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Input } from '../form/validatedInput';
import Loader from '../global/loader';
import { MultiSelect } from '../form/validatedMultiSelect';
import { MultiValue } from 'react-select';
import { userSchema } from '../../store/users/schema';
import { ukPhonePrefix } from '../../helpers/factory';
import Joi from 'joi';

export const EditUser: React.FC<{ isCreate: boolean, user: User, updateUser: (user: User) => void, onSubmit: (user: User) => void }> = ({ isCreate, user, updateUser, onSubmit }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);

  const { activeUserGroups, availableTenants, allTenants, isLoading, error } = useSelector(
    (state: MainState) => ({
      activeUserGroups: state.authentication.authGroups,
      isLoading: state.users.isCreating,
      error: state.users.createError,
      availableTenants: state.authentication.tenants,
      allTenants: state.tenants.tenants
    }),
    shallowEqual
  );

  const roleOptions: RoleSelectOption[] = Object.values(AuthGroup).map((group) => ({ value: group, label: group.toString() }));
  const userRoles: RoleSelectOption[] = roleOptions.filter((o) => user.authGroups.some((g) => g === o.value));
  const accessibleTenants = isAdmin(activeUserGroups) ? allTenants : availableTenants

  const onRoleChange = (values: MultiValue<RoleSelectOption>) => {
    updateUser({ ...user, authGroups: values.map((v: RoleSelectOption) => v.value) });
  }

  const tenantOptions = accessibleTenants.map((tenant) => ({ value: tenant.tenantId, label: tenant.name }))
  const tenantIds = user.tenants as unknown as string[]
  const userTenants: TenantSelectOption[] = tenantOptions.filter((o) => tenantIds.some((t) => t === o.value));

  const onTenantChange = (values: MultiValue<TenantSelectOption>) => {
    updateUser({ ...user, tenants: values.map((v: TenantSelectOption) => v.value) });
  }

  const onUpdateUser = () => {
    setSubmitted(true);
    const { error } = userSchema.validate(user);
    if (!error) {
      onSubmit(user);
    }
  }

  return (
    <div className="modal-form">
      <Loader isLoading={isLoading} />
      {!isLoading &&
        <>
          {error && <p className='error-message'>{error?.message}</p>}
          <div className='modal-form-fields-scroll-wrapper'>
            <fieldset className="modal-form-fields">
              <Input
                type='text'
                label='Username'
                value={user.username}
                schema={userSchema.extract('username')}
                onChange={(value) => updateUser({ ...user, username: value })}
                touchedOverride={submitted}
              />
              <Input
                type='text'
                label='Email'
                value={user.email}
                schema={userSchema.extract('email')}
                onChange={(value) => updateUser({ ...user, email: value })}
                touchedOverride={submitted}
              />
              <Input
                prefix={ukPhonePrefix}
                type='tel'
                label='Phone Number'
                value={user.phoneNumber?.substring(3) || ''}
                schema={Joi.string().length(10).pattern(/^[0-9]+$/).required()}
                onChange={(value) => updateUser({ ...user, phoneNumber: `${ukPhonePrefix}${value}` })}
                touchedOverride={submitted}
              />
              <MultiSelect
                label='Roles'
                options={roleOptions}
                selectedOptions={userRoles}
                schema={userSchema.extract('authGroups')}
                onChange={onRoleChange}
                touchedOverride={submitted}
              />
              <MultiSelect
                label='Tenants'
                options={tenantOptions}
                selectedOptions={userTenants}
                schema={userSchema.extract('tenants')}
                onChange={onTenantChange}
                touchedOverride={submitted}
              />
            </fieldset>
          </div>

          <div className='modal-form-button-wrapper'>
            <button onClick={onUpdateUser}>Save</button>
          </div>
        </>
      }
    </div>
  )
}