import * as actions from './constants';

const initialState: JobState = {
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isUpdatingApprovalStatus: false,
  jobs: [],
  brands: []
}

const reducer = (
  state: JobState = initialState,
  action: JobAction
  ): JobState => {
  const addJob = (job: Job) => {
    return state.jobs.concat(job)
  }
  
  const updateJob = (job: Job) => {
    return state.jobs.map((j: Job) => j.id === job.id ? job : j)
  }

  const updateNewJobs = () => {
    const job: Job = action.response.job;
    const alreadyExists = state.jobs.some((j: Job) => j.id === job.id)
    return alreadyExists ? updateJob(job) : addJob(job)
  }
  
  switch (action.type) {
    case actions.SET_LOADING_JOBS:
      return { ...state, isLoading: true, loadError: undefined }
    case actions.LOAD_JOBS:
      if (action.response.error) {
        return { ...state, isLoading: false, loadError: action.response.error };
      }
      return {
        ...state,
        isLoading: false,
        jobs: action.response.jobs,
        brands: action.response.brands
      }
    case actions.SET_CREATING_JOB:
      return { ...state, isCreating: true, createError: undefined }
    case actions.CREATE_JOB:
      if (action.response.error) {
        return { ...state, isCreating: false, createError: action.response.error };
      }
      return {
        ...state,
        isCreating: false,
        jobs: updateNewJobs(),
      }
    case actions.SET_UPDATING_JOB:
      return { ...state, isUpdating: true, updateError: undefined }
    case actions.UPDATE_JOB:
      if (action.response.error) {
        return { ...state, isUpdating: false, updateError: action.response.error };
      }
      return {
        ...state,
        isUpdating: false,
        jobs: updateNewJobs()
      }
    case actions.SET_DELETING_JOB:
      return { ...state, isDeleting: true, deleteError: undefined }
    case actions.DELETE_JOB:
      if (action.response.error) {
        return { ...state, isDeleting: false, deleteError: action.response.error };
      }
      return {
        ...state,
        isDeleting: false,
        jobs: state.jobs.filter((job) => job.id !== action.response.job.id),
      }
    case actions.SET_UPDATING_JOB_APPROVAL_STATUS:
      return { ...state, isUpdatingApprovalStatus: true, updateApprovalStatusError: undefined }
    case actions.UPDATE_JOB_APPROVAL_STATUS:
      if (action.response.error) {
        return { ...state, isUpdatingApprovalStatus: false, updateApprovalStatusError: action.response.error };
      }
      const { id, approvalStatus } = action.response.job;
      return {
        ...state,
        isUpdatingApprovalStatus: false,
        jobs: state.jobs.map((j) => j.id === id ? { ...j, approvalStatus } : j),
      }
    case actions.UPDATE_ID:
      return {
        ...state,
        isUpdating: false,
        jobs: state.jobs.map((job) => job.id === action.response.initial ? { ...job, id: action.response.new } : job)
      }
    case actions.RESET:
      return initialState;
    case actions.EDITED:
      return {
        ...state,
        isUpdating: false,
        isCreating: false,
        isDeleting: false,
        isUpdatingApprovalStatus: false
      }
    default: return state;
  }
}

export default reducer