import { shallowEqual, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Dispatch } from 'redux';
import { loadJobs } from '../../../store/jobs/actions';

export const useLoadJobs = (dispatch: Dispatch<any>) => {
  const { username, locations } = useSelector(
    (state: MainState) => ({
      locations: state.authentication.locations,
      username: state.authentication.username
    }),
    shallowEqual
  );

  const [location, setLocation] = useState(locations[0]);

  useEffect(() => {
    if (location) {
      dispatch(loadJobs(location, username || ""));
    }
  }, [dispatch, location]);

  useEffect(() => {
    setLocation(locations[0]);
  }, [locations]);

  return { location, setLocation }
}