import { useEffect, useState } from 'react';
import { request } from '../../../helpers/request';
import config from '../../../helpers/config';

const useLoadAuditRecords = () => {
  const [items, setItems] = useState<AuditRecord[]>([]);
  const [noMoreResults, setNoMoreResults] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const fetchItems = async (lastRequestedKey: string = "") => {
    if(noMoreResults) return
    setLoading(true)
    try {
      const records: AuditRecord[] = await request("GET", `${config.apiUrl}/fulfilment/audit-items?from=${lastRequestedKey}&pageSize=10`);
      const recordsWithoutDupe = records?.filter(rec => rec.id !== lastRequestedKey)
      if(recordsWithoutDupe.length) {
        setItems(prev => [...prev, ...recordsWithoutDupe]);
      } else {
        setNoMoreResults(true)
      }
    } catch (error) {
      console.error('Error fetching items:', error);
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const getNextPage = (lastRequestedKey: string) => {
    fetchItems(lastRequestedKey);
  };

  const refresh = () => {
    setItems([])
    fetchItems()
  }

  return { noMoreResults, getNextPage, items, resultsLoading: loading, refresh };
};

export default useLoadAuditRecords;
