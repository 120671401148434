import * as Joi from 'joi';

export enum PassSector {
  Aviation = 'Aviation',
  Hospitality = 'Hospitality',
  Retail = 'Retail',
  AirsideOperation = 'Airside Operations',
  Logistics = 'Logistics'
}

export enum ApprovalStatus {
  AwaitingApproval,
  Approved,
  Rejected,
  Expired
};

export const passSchema = Joi.object().keys({
  timestamp: Joi.any().optional(),
  id: Joi.any().allow('', null).optional(),
  employeename: Joi.string().allow('', null).optional(),
  employeeid: Joi.number().allow('', null).optional(),
  role: Joi.string().allow('', null).optional(),
  passid: Joi.string().allow('').optional().custom((value, helpers) => {
    if (value && !/\d/.test(value)) {
      return helpers.message({ custom: 'Pass ID must contain a number' });
    }
    return value;
  }),
  airport: Joi.string().valid("EMA", "STN", "MAN").optional()
}).custom((value, helpers) => {
  const { employeename, employeeid, role, passid } = value;

  if (!(employeename || employeeid || role || passid)) {
    return helpers.error('custom.atLeastOneProperty');
  }

  return value;
}).messages({
  'custom.atLeastOneProperty': 'At least one of employeename, employeeid, role, or passid must have a value',
  'custom.passid': 'passid must contain a number',
});





export const validatePass = (pass: Pass) => passSchema.validate(pass);