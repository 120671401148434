import * as actions from './constants';

import { AuthTenant } from '../../helpers/authentication';
import config from '../../helpers/config';
import { externalRequest, request } from '../../helpers/request';
import { getNamedAttribute } from '../../helpers/passManagement';

export function resetPassState() {
  return (dispatch: PassDispatchType) => {
    dispatch({ type: actions.RESET });
  }
}

export function loadPasses(location: AuthTenant) {
  const action: PassAction = { type: actions.LOAD_PASSES };

  return async (dispatch: PassDispatchType) => {
    try {
      dispatch({ type: actions.SET_LOADING_PASSES });
      const data = await request('GET', `${config.apiUrl}/pass-management/incomplete?tenant=${location}`);
      dispatch({ ...action, response: { passes: data.passes } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function createPass(pass: Pass, tenant: AuthTenant) {
  const action: PassAction = { type: actions.CREATE_PASS };

  return async (dispatch: PassDispatchType) => {
    try {
      dispatch({ type: actions.SET_CREATING_PASS });
      const body = { pass };
      const data = await request('POST', `${config.apiUrl}/pass-management?tenant=${tenant}`, body);
      dispatch({ ...action, response: { pass: data.pass } });
      const message = `Successfully created record for ${getNamedAttribute(pass)}.`
      dispatch({
        type: actions.ADD_TOAST,
        response: { data:  { message, type: "success" }}
      })
    } catch (error) {
      const err = error as Error
      err.message = err.message.includes(actions.ALREADY_EXISTS_ERROR) ? "A user with this Pass ID already exists. They must be unique. Search for the Pass ID and see which user you wish to keep, or if this Pass ID was a mistake." : "Error adding the pass details. Please contact your administrator."
      dispatch({ ...action, response: { error } });
    }
  }
}

export function updatePass(tenant: AuthTenant, pass: Pass, type: "searchResults" | "passes") {
  const action: PassAction = { type: actions.UPDATE_PASS };
  return async (dispatch: PassDispatchType) => {
    try {
      dispatch({ type: actions.SET_UPDATING_PASS });
      const body = { passes: [pass] };
      await request('PUT', `${config.apiUrl}/pass-management?tenant=${tenant}`, body);
      dispatch({ ...action, response: { pass, type} });
      const message = `Edited pass with name ${getNamedAttribute(pass)}`
      dispatch({
        type: actions.ADD_TOAST,
        response: { data:  { message, type: "success" }}
      })
    } catch (error) {
      const err = error as Error
      err.message = err.message.includes(actions.ALREADY_EXISTS_ERROR) ? "A user with this Pass ID already exists. They must be unique. Search for the Pass ID and see which user you wish to keep, or if this Pass ID was a mistake." : "Error updating the pass details. Please contact your administrator."
      dispatch({ ...action, response: { error } });
      const message = `Error editing pass with name ${getNamedAttribute(pass)}`
      dispatch({
        type: actions.ADD_TOAST,
        response: { data:  { message, type: "error" }}
      })
    }
  }
}

export function deletePass(pass: Pass, location: AuthTenant, type: "searchResults" | "passes") {
  const action: PassAction = { type: actions.DELETE_PASS };

  return async (dispatch: PassDispatchType) => {
    try {
      dispatch({ type: actions.SET_DELETING_PASS });
      await request('DELETE', `${config.apiUrl}/pass-management/${pass.id}?tenant=${location}`);
      dispatch({ ...action, response: { pass, type } })
      const message = `Deleted record for ${getNamedAttribute(pass)}`
      dispatch({
        type: actions.ADD_TOAST,
        response: { data:  { message, type: "information" }}
      })
    } catch (error) {
      const message = `Error deleting pass with name ${getNamedAttribute(pass)}`
      dispatch({
        type: actions.ADD_TOAST,
        response: { data:  { message, type: "error" }}
      })
      dispatch({ ...action, response: { error } });
    }
  }
}

export function uploadFile(file: File, tenant: AuthTenant) {
  const action: PassAction = { type: actions.CREATE_FILE };

  return async (dispatch: PassDispatchType) => {
    try {
      dispatch({ type: actions.SET_CREATING_FILE });
      const urlKey = encodeURI(file.name)
      const s3Url = await request('GET', `${config.apiUrl}/pass-management/file-upload-url?tenant=${tenant}&key=${urlKey}`);
      await externalRequest('PUT', s3Url.url, file);
      dispatch({ ...action });
      dispatch({
        type: actions.ADD_TOAST,
        response: { data:  { message: "File uploaded. See the Past Upload Table to see the status of it.", type: "success" }}
      })
    } catch (error) {
      dispatch({
        type: actions.ADD_TOAST,
        response: { data:  { message: "Unknown error uploading the file.", type: "error" }}
      })
      dispatch({ type: actions.CREATE_FILE_ERROR, response: { error } });
    }
  }
}

export function searchPasses(searchTerm: string, tenant: AuthTenant) {
  const action: PassAction = { type: actions.SET_SEARCH_RESULTS }
  return async (dispatch: PassDispatchType) => {
    try {
      dispatch({ type: actions.SET_SEARCHING_PASSES })
      const encodedSearchTerm = encodeURIComponent(searchTerm)
      const data = await request("GET", `${config.apiUrl}/pass-management/search?tenant=${tenant}&search=${encodedSearchTerm}`)
      dispatch({ ...action, response: { passes: data.passes } })
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function clearSearchResults() {
  const action: PassAction = { type: actions.SET_SEARCH_RESULTS }
  return async (dispatch: PassDispatchType) => {
    try {
      dispatch({ ...action, response: { passes: [] } })
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function resetErrors() {
  const action: PassAction = { type: actions.RESET }
  return async (dispatch: PassDispatchType) => {
      dispatch({ ...action, response: {} })
  }
}

export function removeToast(toast: ToastNotification) {
  const action: PassAction = { type: actions.REMOVE_TOAST }
  return async (dispatch: PassDispatchType) => {
      dispatch({ ...action, response: { data: toast} })
  }
}