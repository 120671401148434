import { Select as ValidatedSelect } from '../../form/validatedSelect';
import { manageSchema } from '../../../store/manage/schema';
import { shallowEqual, useSelector } from 'react-redux';

import { UploadImage } from './uploadImage';
import { Input } from '../../form/validatedInput';

type PropTypes = {
  logo: LogoDetails,
  tenant: Tenant,
  updateLogo: (logo: LogoDetails) => void,
  touchedOverride?: boolean
}

export const LogoFields: React.FC<PropTypes> = ({ updateLogo, touchedOverride, logo, tenant }) => {

  const { tenants } = useSelector(
    (state: MainState) => ({
      tenants: state.manage.allowedTenants
    }),
    shallowEqual
  );

  const brandOptions = tenants.map(tenant => ({ 
    value: tenant.tenantId, 
    label: tenant.name }
  ))

  const logoOptions = tenant.logoTitles?.map((logo) => ({
    label: logo,
    value: logo
  })) || []

  const fields = [
    {
      type: "employer",
      prop: "tenantId",
      label: "Employer",
      options: brandOptions,
      disabled: true
    },
    { type: "select", prop: "chooseLogo", label: "See What You've Already Uploaded:", options: logoOptions},
    { type: "text", prop: "title", label: "Logo Title" },
    { type: "uploadImage", prop: "uploadLogo", label: "Upload Logo"},
  ];

  return (
    <>
      {fields.map((field) => {
        switch (field.type) {
          case "text":
            return <Input
              key={field.prop}
              type="text"
              label={field.label}
              schema={manageSchema.extract(field.prop)}
              onChange={(value) => updateLogo({...logo, title: value})}
              value={logo.title}
              disabled={field.disabled}
            />
          case "select":
            return <ValidatedSelect
              disabled={field.disabled}
              key={field.prop}
              label={field.label}
              options={field.options as SelectOption[]}
              value={logo.value}
              onChange={() => null}
              touchedOverride={touchedOverride} 
              schema={manageSchema.extract(field.prop)}
            />
          case "uploadImage":
            return <UploadImage 
              key={field.prop}
              label={field.label}
              logoDetails={logo}
              updateLogo={updateLogo}
              />
          case "employer": 
          return (
            <ValidatedSelect
              options={brandOptions}
              label={field.label}
              value={logo.tenantId}
              schema={manageSchema.extract('tenantId')}
              onChange={(value) => updateLogo({...logo, tenantId: value})}
              touchedOverride={touchedOverride}
              key={field.prop}
            />
          )
          default:
            throw new Error(`Invalid type: ${field.type}`);
        }
      })
      }
    </>
  );
};