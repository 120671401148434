import { ChangePasswordForm } from '../../components/authentication/changePasswordForm';
import NavigationBar from '../../components/navigation/navigationBar';
import React from 'react';

const ChangePassword: React.FunctionComponent<{}> = () => {
  return (
    <div className="wrapper">
      <NavigationBar />

      <header className="header">
        <section className="container">
          <ChangePasswordForm />
        </section>
      </header>
    </div>
  )
}

export default ChangePassword