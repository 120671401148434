import { AuthTenant } from '../../helpers/authentication';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Input } from '../form/validatedInput';
import Loader from '../global/loader';
import { MultiSelect } from '../form/validatedMultiSelect';
import { MultiValue } from 'react-select';
import { tenantSchema } from '../../store/tenants/schema';
import { defaultTenant, locationText } from '../../helpers/factory';
import { Select } from '../form/validatedSelect';

export const EditTenant: React.FC<{ isCreate: boolean, tenant: Tenant, updateTenant: (Tenant: Tenant) => void, onSubmit: (tenant: Tenant) => void }> = ({ isCreate, tenant, updateTenant, onSubmit }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);

  const { isLoading, error, allTenants } = useSelector(
    (state: MainState) => ({
      isLoading: state.tenants.isUpdating,
      error: state.tenants.updateError,
      allTenants: state.tenants.tenants
    }),
    shallowEqual
  );

  const onSubTenantChange = (values: MultiValue<SelectOption>) => {
    updateTenant({ ...tenant, subTenants: values.map((v: TenantSelectOption) => v.value) });
  }

  const locationOptions: SelectOption[] = Object.values(AuthTenant).map((tenant) => ({ value: tenant, label: locationText(tenant) }));
  const tenantLocation: SelectOption = locationOptions.find((o) => tenant.location === o.value) || defaultTenant().location;
  const tenantOptions = allTenants.map((tenant) => ({ value: tenant.tenantId, label: tenant.name }))
  const subTenants: TenantSelectOption[] = tenantOptions.filter((o) => tenant.subTenants.some((t) => t === o.value))


  const onUpdateTenant = () => {
    setSubmitted(true);
    const { error } = tenantSchema.validate(tenant);
    if (!error) {
      onSubmit(tenant);
    }
  }

  return (
    <div className="modal-form">
      <Loader isLoading={isLoading} />
      {!isLoading &&
        <>
          {error && <p className='error-message'>{error?.message}</p>}
          <div className='modal-form-fields-scroll-wrapper'>
            <fieldset className="modal-form-fields">
            <Input
                type='text'
                label='Name'
                value={tenant.name}
                schema={tenantSchema.extract('name')}
                onChange={(value) => updateTenant({ ...tenant, name: value })}
                touchedOverride={submitted}
              />
              <Input
                type='text'
                label='Contact'
                value={tenant.contact}
                schema={tenantSchema.extract('contact')}
                onChange={(value) => updateTenant({ ...tenant, contact: value })}
                touchedOverride={submitted}
              />
              <Select
                label='Location'
                options={locationOptions}
                value={tenantLocation.value}
                schema={tenantSchema.extract('location')}
                onChange={(value: string) => updateTenant({ ...tenant, location: value })}
                touchedOverride={submitted}
              />
              <Input
                type='text'
                label='Description'
                value={tenant.description}
                schema={tenantSchema.extract('description')}
                onChange={(value) => updateTenant({ ...tenant, description: value })}
                touchedOverride={submitted}
              />
              <MultiSelect
                label='Sub Tenants'
                options={tenantOptions}
                selectedOptions={subTenants}
                schema={tenantSchema.extract('subTenants')}
                onChange={onSubTenantChange}
                touchedOverride={submitted}
              />
            </fieldset>
          </div>

          <div className='modal-form-button-wrapper'>
            <button onClick={onUpdateTenant}>Save</button>
          </div>
        </>
      }
    </div>
  )
}