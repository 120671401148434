type PrefixPropTypes = {
    value: string,
    size: number,
    siblingInput: JSX.Element
}

export const FormPrefix: React.FC<PrefixPropTypes> = ({
    value,
    size,
    siblingInput
}) => {
    const showPhoneWarning = siblingInput.props?.type === "tel"
    return (
        <>
            {showPhoneWarning && <p>Phone number is used for verification and two-factor authentication.
                Ensure it is a +44 number that the user owns. Do not enter the leading 0.</p>}
            <div className="prefixContainer">
                <input className="prefixInput" type='text' size={size} disabled value={value} />
                {siblingInput}
            </div>
        </>
    )
}