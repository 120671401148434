import NavigationBar from '../components/navigation/navigationBar';
import { UsersList } from '../components/users/usersList';

const Users: React.FC = () => {
  return (
    <div className="wrapper">
      <NavigationBar />
      <section className="container">
        <UsersList />
      </section>
    </div>
  );
}

export default Users