import React, { useCallback, useState } from 'react'
import { clearSearchResults, createPass, deletePass, resetErrors, searchPasses, updatePass, uploadFile } from '../../store/passManagement/actions';
import { defaultPass } from '../../helpers/factory';
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ManagePassDetails } from './managePass';
import { Dispatch } from 'redux'
import Loader from '../global/loader';
import Modal from '../global/modal';
import { LocationSelect } from '../form/locationSelect';
import { useCloseConfirmAfterLoad } from './hooks/useCloseConfirmAfterLoad';
import { useCloseCreateAfterLoad } from './hooks/useCloseCreateAfterLoad';
import { useCloseEditAfterLoad } from './hooks/useCloseEditAfterLoad';
import { useLoadPasses } from './hooks/useLoadPasses';
import { useCloseExcelUploadAfterLoad } from './hooks/useCloseExcelUploadAfterLoad';
import { UploadFile } from './form/uploadFile';
import { ConfirmModal } from '../global/confirmModal';
import { AuthTenant, isPassManagementWriter } from '../../helpers/authentication';
import { Link } from 'react-router-dom';

export const PassList: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { location, setLocation } = useLoadPasses(dispatch);
  const [file, setFile] = useState<File | undefined>();
  const [pass, setPass] = useState<Pass>({});
  const [searchTerm, setSearchTerm] = useState("")
  const [searchActive, setSearchActive] = useState(false)
  const { isCreateOpen, setIsCreateOpen } = useCloseCreateAfterLoad();
  const { isExcelUploadOpen, setIsExcelUploadOpen } = useCloseExcelUploadAfterLoad();
  const { isEditOpen, setIsEditOpen } = useCloseEditAfterLoad();
  const { isConfirmOpen, setIsConfirmOpen, isDeleting, deleteError } = useCloseConfirmAfterLoad();
  const [deletingPass, setDeletingPass] = useState<Pass>(defaultPass());
  const resultsType = searchActive && searchTerm ? "searchResults" : "passes"


  const { isLoading, loadError, passes, searchResults, passManagementPerms } = useSelector(
    (state: MainState) => ({
      roles: state.authentication.authGroups,
      username: state.authentication.username,
      isLoading: state.passManagement.isLoading,
      loadError: state.passManagement.loadError,
      passes: state.passManagement.passes,
      searchResults: state.passManagement.searchResults,
      passManagementPerms: state.authentication.passManagementPermissions!
    }),
    shallowEqual
  );

  const onOpenCreatePass = () => {
    setPass({})
    setIsCreateOpen(true);
  }

  const onOpenExcelUpload = () => {
    setIsExcelUploadOpen(true);
  }

  const onCreatePass = useCallback(
    (pass: Pass) => dispatch(createPass(pass, location)),
    [dispatch, location]
  );

  const onOpenDeletePass = (pass: Pass) => {
    setDeletingPass(pass);
    setIsConfirmOpen(true);
  }

  const onDeletePass = useCallback(
    (pass: Pass) => dispatch(deletePass(pass, location, resultsType)),
    [dispatch, location, deletingPass]
  );

  const onOpenEditPass = (pass: Pass) => {
    setPass(pass);
    setIsEditOpen(true);
  }

  const onEditPass = useCallback(
    (pass: Pass) => dispatch(updatePass(location, pass, resultsType)),
    [dispatch, location]
  );

  const onUploadFile = useCallback(
    (file: File) => {
      if (file) {
        dispatch(uploadFile(file, location))
      }
    },
    [dispatch, location]
  );

  const clearSearch = () => {
    setSearchActive(false)
    setSearchTerm("")
    dispatch(clearSearchResults())
  }

  const search = useCallback((term: string, loc: AuthTenant) => {
    dispatch(searchPasses(term, loc))
    setSearchActive(true)
  }, [])

  const closeWithReset = (funcRef: Function) => {
    dispatch(resetErrors())
    funcRef(false)
  }

  const hasWritePerms = isPassManagementWriter(passManagementPerms, location)

  const tableData = searchActive ? searchResults : passes;

  return (
    <main>
      <h1>Pass Management</h1>
      <LocationSelect value={location} onChange={setLocation} optionsProp='passManagementTenants' />
      <Loader isLoading={isLoading} />
      {hasWritePerms &&
        <>
          <Modal handleClose={() => closeWithReset(setIsCreateOpen)} isOpen={isCreateOpen}>
            <ManagePassDetails pass={pass} setPass={setPass} onSubmit={onCreatePass} action="Add" airport={location} />
          </Modal>
          <Modal handleClose={() => closeWithReset(setIsEditOpen)} isOpen={isEditOpen}>
            <ManagePassDetails pass={pass} setPass={setPass} action='Edit' airport={location} onSubmit={onEditPass} />
          </Modal>
          <Modal handleClose={() => closeWithReset(setIsExcelUploadOpen)} isOpen={isExcelUploadOpen}>
            <UploadFile fileDetails={file!} setFile={setFile} onSubmit={onUploadFile} />
          </Modal>
          <ConfirmModal
            message='Are you sure you want to delete these pass details?'
            isLoading={isDeleting}
            error={deleteError?.message}
            onConfirm={() => onDeletePass(deletingPass)}
            handleClose={() => setIsConfirmOpen(false)}
            isOpen={isConfirmOpen}
          />
          <button style={{ width: "100%" }} onClick={onOpenCreatePass}>Add Employee Details</button>
          <button style={{ width: "100%" }} onClick={onOpenExcelUpload}>Upload Employee Details via Excel Sheet</button>
          <Link to="/bulk-upload-audit" state={{ tenant: location }}><button style={{ width: "100%" }}>See Past Excel Uploads Status</button></Link>
        </>
      }
      <div className="search-container">
        <div className='bar-wrapper'>
          <input type="text"
            value={searchTerm}
            className="searchTerm"
            onKeyDown={(e) => e.key === "Enter" ? search(searchTerm, location) : null}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search All Employee Records"
          />
          <button className="clear-button" onClick={clearSearch}><i className="icon-cancel"></i></button>
        </div>
        <button className="search-button" onClick={() => search(searchTerm, location)}>Search</button>
      </div>
      {!isLoading && loadError && <p className='error-message'>{loadError?.message}</p>}
      {!isLoading && !loadError &&
        <>
          <h3 className='tableHeader'>{searchActive ? "Search Results" : "Incomplete Details"}</h3>
          <table className="react-table">
            <thead>
              <tr>
                <th>Employee Name</th>
                <th>Role</th>
                <th>Employee ID</th>
                <th>Pass ID</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((pass: Pass, i: number) => (
                <tr key={`${pass.employeeid}-${i}`}>
                  <td>{pass.employeename}</td>
                  <td>{pass.role}</td>
                  <td>{pass.employeeid}</td>
                  <td>{pass.passid}</td>
                  <td>
                    <button title="Edit Pass" onClick={() => onOpenEditPass(pass)} disabled={!hasWritePerms} className={"button-small icon-pencil"}></button>
                    <button title="Delete Pass" onClick={() => onOpenDeletePass(pass)} disabled={!hasWritePerms} className={"button-small icon-cancel"}></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      }
    </main>
  );
}
