import NavigationBar from '../components/navigation/navigationBar';
import { RedirectsList } from '../components/redirects/redirectsList';

const Redirects: React.FC = () => {
  return (
    <div className="wrapper">
      <NavigationBar />
      <section className="container">
        <RedirectsList />
      </section>
    </div>
  );
}

export default Redirects