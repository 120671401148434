import { ApprovalStatus, approvalNotesSchema, jobSchema } from '../../store/jobs/schema';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import Loader from '../global/loader';
import { TextArea } from '../form/validatedTextArea';

type PropTypes = {
  job: Job,
  approvalStatus: ApprovalStatus,
  onUpdateApprovalStatus: (job: Job, approvalStatus: ApprovalStatus, approvalNotes?: string) => void
}

export const UpdateJobApproval: React.FC<PropTypes> = ({ job, approvalStatus, onUpdateApprovalStatus }) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<any>();
  const [approvalNotes, setApprovalNotes] = useState<string | undefined>();

  const { isLoading, approvalError } = useSelector(
    (state: MainState) => ({
      isLoading: state.jobs.isUpdatingApprovalStatus,
      approvalError: state.jobs.updateApprovalStatusError
    }),
    shallowEqual
  );

  const schema = approvalStatus === ApprovalStatus.Rejected ? approvalNotesSchema : jobSchema.extract('approvalNotes');
  const statusLabel = approvalStatus === ApprovalStatus.Approved ? 'approve' : 'reject';

  useEffect(() => {
    const { error } = schema.validate(approvalNotes);
    setValidationError(error);
  }, [schema, approvalNotes]);


  const onSubmitClick = () => {
    setSubmitted(true);
    const { error } = schema.validate(approvalNotes);
    if (!error) {
      onUpdateApprovalStatus(job, approvalStatus, approvalNotes);
    }
  }

  return (
    <div className="modal-form">
      <Loader isLoading={isLoading} />
      {!isLoading &&
        <>
          <div className='modal-form-fields-scroll-wrapper'>
            <fieldset className="modal-form-fields">
            {approvalError && <p className='error-message'>{approvalError?.message}</p>}
              <p>Are you sure you want to {statusLabel} this job?</p>
              <TextArea
                label={'Notes'}
                value={approvalNotes}
                schema={jobSchema.extract('approvalNotes')}
                onChange={setApprovalNotes}
                touchedOverride={submitted}
              />
            </fieldset>
          </div>

          <div className='modal-form-button-wrapper'>
            {submitted && validationError && <p className='error-message'>{validationError.message}</p>}
            <button disabled={validationError !== undefined} onClick={onSubmitClick}>Confirm</button>
          </div>
        </>
      }
    </div>
  );
}