import axios, { AxiosRequestConfig, Method } from 'axios';

import { getSession } from './cognito';

export const request = async (method: Method, url: string, body?: any) => {
  try {
    const session = await getSession();
    const options: AxiosRequestConfig = {
      baseURL: url,
      method: method,
      data: body,
      headers: {
        'Authorization': session.getIdToken().getJwtToken(),
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      }
    };
    const response = await axios.request(options);
    return response.data;
  } catch (error: any) {
    if(error.response?.data) {
      error.message = error.response.data
    } 
    if (axios.isAxiosError(error)) {
      throw new Error(error.message);
    } else {
      throw error;
    }
  }
}

export const externalRequest = async (method: Method, url: string, body?: any, headers?: any) => {
  try {
    const options: AxiosRequestConfig = {
      baseURL: url,
      method: method,
      data: body,
      headers
    };
    const response = await axios.request(options);
    return response.data;
  } catch (error: any) {
    if(error.response?.data) {
      error.message = error.response.data
    } 
    if (axios.isAxiosError(error)) {
      throw new Error(error.message);
    } else {
      throw error;
    }
  }
}