import * as actions from './constants';

import { ApprovalStatus } from './schema';
import { AuthTenant } from '../../helpers/authentication';
import config from '../../helpers/config';
import { loadNotificationsAction } from '../authentication/actions';
import { request } from '../../helpers/request';
import { EDITED } from './constants';

export function resetJobsState() {
  return (dispatch: JobDispatchType) => {
    dispatch({ type: actions.RESET });
  }
}

export function loadJobs(location: AuthTenant, username: string) {
  const action: JobAction = { type: actions.LOAD_JOBS };

  return async (dispatch: JobDispatchType) => {
    try {
      dispatch({ type: actions.SET_LOADING_JOBS });
      const data = await request('GET', `${config.apiUrl}/recruitment?location=${location}`);
      const allBrands = await request('GET', `${config.apiUrl}/users/${username}/allTenants`);
      dispatch({ ...action, response: { jobs: data.jobs, brands: allBrands.tenants } });
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function createJob(job: Job) {
  const action: JobAction = { type: actions.CREATE_JOB };

  const emailRegex = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;

  if (job.url && emailRegex.test(job.url) && !job.url.startsWith('mailto:')) {
    job.url = `mailto:${job.url}`
  }

  return async (dispatch: JobDispatchType) => {
    try {
      dispatch({ type: actions.SET_CREATING_JOB });
      const body = { job };
      delete job.id;
      const data = await request('POST', `${config.apiUrl}/recruitment?location=${job.tenant}`, body);
      dispatch({ ...action, response: { job: data.job } });
      loadNotificationsAction(dispatch);
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function updateJob(tenant: AuthTenant, job: Job) {
  const action: JobAction = { type: actions.UPDATE_JOB };
  return async (dispatch: JobDispatchType) => {
    try {
      if ([ApprovalStatus.AwaitingApproval, ApprovalStatus.Rejected].includes(job.approvalStatus)) {
        dispatch({ type: actions.SET_DELETING_JOB })
        dispatch({ type: actions.DELETE_JOB, response: { job: job } })
      }

      dispatch({ type: actions.SET_UPDATING_JOB });
      const body = { job };
      const data = await request('PUT', `${config.apiUrl}/recruitment?location=${tenant}`, body);
      if ((data.job as Job).id === job.id || job.id?.includes(EDITED)) {
        dispatch({ ...action, response: { job: data.job } });
      } else {
        dispatch({ type: actions.CREATE_JOB, response: { job: data.job } });
      }
      dispatch({ type: actions.EDITED })
      loadNotificationsAction(dispatch);
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function deleteJob(tenant: AuthTenant, job: Job) {
  const action: JobAction = { type: actions.DELETE_JOB };

  return async (dispatch: JobDispatchType) => {
    try {
      dispatch({ type: actions.SET_DELETING_JOB });
      await request('DELETE', `${config.apiUrl}/recruitment/${job.id}?location=${tenant}`);
      dispatch({ ...action, response: { job } });
      loadNotificationsAction(dispatch);
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}

export function updateJobApprovalStatus(tenant: AuthTenant, id: string, approvalStatus: ApprovalStatus, createdBy?: string, approvalNotes?: string) {
  const action: JobAction = { type: actions.UPDATE_JOB };

  return async (dispatch: JobDispatchType) => {
    try {
      dispatch({ type: actions.SET_UPDATING_JOB_APPROVAL_STATUS })
      const isEdited = id.includes(EDITED)
      if (isEdited) {
        if (approvalStatus === ApprovalStatus.Approved) {
          dispatch({
            type: actions.SET_UPDATING_JOB
          })
          dispatch({
            type: actions.DELETE_JOB,
            response: {
              job: {
                id: id
              }
            }
          })
        }
      }

      const body = { job: { id, approvalStatus, approvalNotes, createdBy } };
      const data = await request('PUT', `${config.apiUrl}/recruitment/approval?location=${tenant}`, body);
      dispatch({ ...action, response: { job: data.job } });
      dispatch({ type: EDITED })
      loadNotificationsAction(dispatch);
    } catch (error) {
      dispatch({ ...action, response: { error } });
    }
  }
}