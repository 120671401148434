import NavigationBar from "../components/navigation/navigationBar";
import { EditTicker } from "../components/ticker/tickerMessages";

const Ticker: React.FC = () => {
  return (
    <div className="wrapper">
      <NavigationBar />
      <section className="container">
        <EditTicker />
      </section>
    </div>
  );
}

export default Ticker