export enum ColumnAlign {
  Left,
  Center,
  Right
}

export const Container: React.FC = ({ children }) => {
  return (<div className="container">{children}</div>);
}

export const Row: React.FC = ({ children }) => {
  return (<div className="row">{children}</div>);
}

export const Column: React.FC<{ offset?: number; width?: number; align?: ColumnAlign }> = ({ children, offset, width, align }) => {
  const offsetClass = offset ? ` column-offset-${offset}` : '';
  const widthClass = width ? ` column-${width}` : '';
  const alignClass = getAlignClass(align);
  return (<div className={`column${offsetClass}${widthClass}${alignClass}`}>{children}</div>);
}

const getAlignClass = (align?: ColumnAlign) => {
  switch (align) {
    case ColumnAlign.Left: return ' align-left';
    case ColumnAlign.Center: return ' align-center';
    case ColumnAlign.Right: return ' align-right';
    default: return '';
  }
}