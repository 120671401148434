import { shallowEqual, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export const useCloseConfirmAfterLoad = () => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const { isDeleting, deleteError } = useSelector(
    (state: MainState) => ({
      isDeleting: state.redirects.isDeleting,
      deleteError: state.redirects.deleteError,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!isDeleting && !deleteError) {
      setIsConfirmOpen(false);
    }
  }, [isDeleting, deleteError]);

  return { isConfirmOpen, setIsConfirmOpen, isDeleting, deleteError }
}