type PropTypes = {
  label: string,
  logoDetails: LogoDetails,
  updateLogo: (logo: LogoDetails) => void,
}

export const UploadImage: React.FC<PropTypes> = ({ label, logoDetails, updateLogo }) => {
const onFileChange = (event: any) => {
  const file = event.target.files[0];
  const logoType = file.type.replace("image/", ".")
    updateLogo({
      ...logoDetails,
      value: file,
      size: file.size,
      type: logoType,
    });
};

  return (
    <>
      <label>{label}</label>
        <input className="uploadImageButton" type="file" id="myImage" name="image" accept=".jpg,.jpeg,.png,.svg" onChange={onFileChange}></input>
    </>
  )
};