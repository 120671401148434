import { useEffect, useRef } from 'react';

import Portal from './portal';

const Modal: React.FC<{ isOpen: boolean, handleClose: () => void }> = ({ children, isOpen, handleClose }) => {
  const nodeRef = useRef(null);
  useEffect(() => {
    const closeOnEscapeKey = (event: KeyboardEvent) => { if (event.key === "Escape") { handleClose(); } };
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [handleClose]);

  return (
    <Portal wrapperId="react-portal-modal-container">
      {isOpen &&
        <div className="modal" ref={nodeRef} onClick={handleClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <>
              <span onClick={handleClose} className="modal-close-btn">X</span>
              {children}
            </>
          </div>
        </div>
      }
    </Portal>
  );
}

export default Modal