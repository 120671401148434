import React, { useCallback, useState } from 'react';
import { createRedirect, deleteRedirect, updateRedirect } from '../../store/redirects/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ConfirmModal } from '../global/confirmModal';
import { CreateRedirect } from './createRedirect';
import { Dispatch } from 'redux'
import { EditRedirect } from './editRedirect';
import Loader from '../global/loader';
import Modal from '../global/modal';
import { LocationSelect } from '../form/locationSelect';
import { defaultRedirect } from '../../helpers/factory';
import { useCloseConfirmAfterLoad } from './hooks/useCloseConfirmAfterLoad';
import { useCloseCreateAfterLoad } from './hooks/useCloseCreateAfterLoad';
import { useCloseEditAfterLoad } from './hooks/useCloseEditAfterLoad';
import { useLoadRedirects } from './hooks/useLoadJobs';

export const RedirectsList: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { location , setLocation } = useLoadRedirects(dispatch);
  const [editingRedirect, setEditingRedirect] = useState<Redirect>(defaultRedirect());
  const { isCreateOpen, setIsCreateOpen } = useCloseCreateAfterLoad();
  const { isEditOpen, setIsEditOpen } = useCloseEditAfterLoad();
  const { isConfirmOpen, setIsConfirmOpen, isDeleting, deleteError } = useCloseConfirmAfterLoad();
  const [deletingRedirect, setDeletingRedirect] = useState<Redirect>(defaultRedirect());

  const { isLoading, loadError, redirects } = useSelector(
    (state: MainState) => ({
      isLoading: state.redirects.isLoading,
      loadError: state.redirects.loadError,
      redirects: state.redirects.redirects
    }),
    shallowEqual
  );

  const onOpenCreateRedirect = () => {
    setIsCreateOpen(true);
  }

  const onCreateRedirect = useCallback(
    (redirect: Redirect) => dispatch(createRedirect(location, redirect)),
    [dispatch, location]
  );

  const onOpenEditRedirect = (redirect: Redirect) => {
    setEditingRedirect(redirect);
    setIsEditOpen(true);
  }

  const onEditRedirect = useCallback(
    (redirect: Redirect) => dispatch(updateRedirect(location, redirect)),
    [dispatch, location]
  );

  const onOpenDeleteRedirect = (redirect: Redirect) => {
    setDeletingRedirect(redirect);
    setIsConfirmOpen(true);
  }

  const onDeleteRedirect = useCallback(
    () => dispatch(deleteRedirect(location, deletingRedirect)),
    [dispatch, location, deletingRedirect]
  );

  const orderedRedirects = redirects.sort((a: Redirect, b: Redirect) => a.key < b.key ? -1 : a.key > b.key ? 1 : 0);

  return (
    <main>
      <h1>Redirects</h1>
      <LocationSelect value={location} onChange={setLocation} />
      <Loader isLoading={isLoading} />
      <Modal handleClose={() => setIsCreateOpen(false)} isOpen={isCreateOpen}>
        <CreateRedirect onSubmit={onCreateRedirect} />
      </Modal>
      <Modal handleClose={() => setIsEditOpen(false)} isOpen={isEditOpen}>
        <EditRedirect redirect={editingRedirect} updateRedirect={setEditingRedirect} onSubmit={onEditRedirect} />
      </Modal>
      <ConfirmModal
        message='Are you sure you want to delete this redirect?'
        isLoading={isDeleting}
        error={deleteError?.message}
        onConfirm={onDeleteRedirect}
        handleClose={() => setIsConfirmOpen(false)}
        isOpen={isConfirmOpen}
      />
      {!isLoading && loadError && <p className='error-message'>{loadError?.message}</p>}
      {!isLoading && !loadError &&
        <>
          <button onClick={() => onOpenCreateRedirect()}>Add Redirect</button>
          <table className='react-table'>
            <thead>
              <tr>
                <th>From</th>
                <th>Host</th>
                <th>To</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orderedRedirects.map((redirect: Redirect) => (
                <tr className="breakRows" key={redirect.key}>
                  <td>{redirect.key}</td>
                  <td>{redirect.host}</td>
                  <td>{redirect.uri}</td>
                  <td>{redirect.status}</td>
                  <td>
                    <button title="Edit Redirect" onClick={() => onOpenEditRedirect(redirect)} className="icon-pencil button-small"></button>
                    <button title="Delete Redirect" onClick={() => onOpenDeleteRedirect(redirect)} className="icon-cancel button-small"></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      }
    </main>
  );
}