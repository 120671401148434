import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Dispatch } from 'react';
import { Theme } from '../../helpers/theme';
import { setTheme } from '../../store/global/actions';

const ThemeToggle: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const theme: Theme = useSelector(
    (state: MainState) => state.global.theme,
    shallowEqual
  );

  const toggleClicked = async () => {
    dispatch(setTheme(theme === Theme.Dark ? Theme.Light : Theme.Dark));
  }

  return (
    <>
      <i className={theme === Theme.Dark ? "icon-moon" : "icon-sun"} />
      <div className="theme-toggle">
        <label className="switch">
          <input type="checkbox" onChange={toggleClicked} checked={theme === Theme.Dark} />
          <span className="slider round"></span>
        </label>
      </div>
    </>
  );
}

export default ThemeToggle