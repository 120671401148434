import React, { useCallback, useEffect, useState } from 'react'
import { createTenant, deleteTenant, loadTenants, resetTenantsState, updateTenant } from '../../store/tenants/actions';
import { defaultTenant, locationText } from '../../helpers/factory';
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { ConfirmModal } from '../global/confirmModal';
import { CreateTenant } from './createTenant';
import { Dispatch } from 'redux'
import { EditTenant } from './editTenant';
import Loader from '../global/loader';
import Modal from '../global/modal';
import { useCloseConfirmAfterLoad } from './hooks/useCloseConfirmAfterLoad';
import { useCloseCreateAfterLoad } from './hooks/useCloseCreateAfterLoad';
import { useCloseEditAfterLoad } from './hooks/useCloseEditAfterLoad';
import { isAdmin } from '../../helpers/authentication';

export const ManageTenants: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const { isCreateOpen, setIsCreateOpen } = useCloseCreateAfterLoad();
  const { isEditOpen, setIsEditOpen } = useCloseEditAfterLoad();
  const { isConfirmOpen, setIsConfirmOpen, isDeleting, deleteError } = useCloseConfirmAfterLoad();
  const [editingTenant, setEditingTenant] = useState<Tenant>(defaultTenant());
  const [deletingTenant, setDeletingTenant] = useState<Tenant>(defaultTenant());

  useEffect(() => {
    dispatch(resetTenantsState())
    dispatch(loadTenants());
  }, [dispatch]);

  const { allTenants, isLoading, loadError, user } = useSelector(
    (state: MainState) => ({
      allTenants: state.tenants?.tenants,
      isLoading: state.tenants.isLoading,
      loadError: state.tenants.loadError,
      user: state.authentication.authGroups
    }),
    shallowEqual
  );

  const onOpenCreateTenant = () => {
    setIsCreateOpen(true);
  }

  const onCreateTenant = useCallback(
    (tenant: Tenant) => dispatch(createTenant(tenant)),
    [dispatch]
  );

  const onOpenEditTenant = (tenant: Tenant) => {
    setEditingTenant(tenant);
    setIsEditOpen(true);
  }

  const onEditTenant = useCallback(
    (tenant: Tenant) => dispatch(updateTenant(tenant)),
    [dispatch]
  );

  const onOpenDeleteTenant = (tenant: Tenant) => {
    setDeletingTenant(tenant);
    setIsConfirmOpen(true);
  }

  const onDeleteTenant = useCallback(
    () => dispatch(deleteTenant(deletingTenant)),
    [dispatch, deletingTenant]
  );

  const orderedTenants = allTenants.sort((a: Tenant, b: Tenant) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);

  return (
    <main>
      <h1>Tenants</h1>
      <Loader isLoading={isLoading} />
      <Modal handleClose={() => setIsCreateOpen(false)} isOpen={isCreateOpen}>
        <CreateTenant onSubmit={onCreateTenant} />
      </Modal>
      <Modal handleClose={() => setIsEditOpen(false)} isOpen={isEditOpen}>
        <EditTenant isCreate={false} tenant={editingTenant} updateTenant={setEditingTenant} onSubmit={onEditTenant} />
      </Modal>
      <ConfirmModal
        message='Are you sure you want to delete this tenant?'
        isLoading={isDeleting}
        error={deleteError?.message}
        onConfirm={onDeleteTenant}
        handleClose={() => setIsConfirmOpen(false)}
        isOpen={isConfirmOpen}
      />
      {!isLoading && loadError && <p className='error-message'>{loadError?.message}</p>}
      {!isLoading && !loadError &&
        <>
          <button onClick={() => onOpenCreateTenant()}>Add Tenant</button>
          <table className='react-table'>
            <thead>
              <tr>
                <th>Tenant Name</th>
                <th>Contact</th>
                <th>Location</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orderedTenants.map((tenant: Tenant) => (
                <tr key={tenant.name}>
                  <td>{tenant.name}</td>
                  <td>{tenant.contact}</td>
                  <td>{locationText(tenant.location)}</td>
                  <td>
                    <button title="Edit Tenant" onClick={() => onOpenEditTenant(tenant)} className="icon-pencil button-small"></button>
                    {isAdmin(user) &&
                      <button title="Delete Tenant" onClick={() => onOpenDeleteTenant(tenant)} className="icon-cancel button-small"></button>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      }
    </main>
  );
}