import { shallowEqual, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { Dispatch } from 'redux';
import { loadPasses } from '../../../store/passManagement/actions';

export const useLoadPasses = (dispatch: Dispatch<any>) => {
  const { locations } = useSelector(
    (state: MainState) => ({
      locations: state.authentication.passManagementTenants || [],
    }),
    shallowEqual
  );

  const [location, setLocation] = useState(locations[0]);

  useEffect(() => {
    if (location) {
      dispatch(loadPasses(location));
    }
  }, [dispatch, location]);

  useEffect(() => {
    setLocation(locations[0]);
  }, [locations]);

  return { location, setLocation }
}