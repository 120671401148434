import * as actions from './constants';

const initialState: ManageState = {
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  hasCreated: false,
  createError: undefined,
  deleteError: undefined,
  allowedTenants: []
}

const reducer = (
  state: ManageState = initialState,
  action: ManageAction
  ): ManageState => {
  switch (action.type) {
    case actions.CREATE_LOGO:
      return { ...state, isLoading: false, isCreating: false, hasCreated: true, createError: undefined }
    case actions.SET_CREATING_LOGO:
      return { ...state, isCreating: true, hasCreated: false, createError: undefined }
    case actions.LOAD_LOGOS:
      return {...state, isLoading: true }
    case actions.CREATE_ERROR:
      return {...state, createError: action.response, isCreating: false, isLoading: false}
      case actions.CREATE_LOGO_ERROR:
        return {...state, createError: action.response, isCreating: false, isLoading: false}
    case actions.ADD_LOGO:
      const logo = action.response.logo
      return {
        ...state,
        allowedTenants: state.allowedTenants.map((t) => {
          if(t.tenantId === logo.tenantId) {
            const fileName = `${logo.title}${logo.type}`
            t.logoTitles = t.logoTitles ? [...t.logoTitles,fileName] : [fileName]
          }
          return t
        })
      }
    case actions.RESET_LOGOS:
      return {
        ...state,
        isCreating: false,
        hasCreated: false,
        createError: undefined
      }
      case actions.LOAD_ALLOWED_TENANTS:
        if (action.response.error) {
          return { ...state, isLoading: false, loadError: action.response.error };
        }
        return {
          ...state,
          isLoading: false,
          allowedTenants: action.response.brands
        }
    case actions.RESET:
      return initialState;
    default: return state;
  }
}

export default reducer