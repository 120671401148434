import { createDefaultLogo, manageSchema } from '../../store/manage/schema';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { LogoFields } from './form/fields';
import Loader from '../global/loader'

type PropTypes = {
  tenant: Tenant,
  onUpdateTenant: (tenantId: string) => void,
  onSubmit: (logo: LogoDetails) => void,
}

export const TenantLogos: React.FC<PropTypes> = ({ tenant, onSubmit, onUpdateTenant }) => {
  const defaultLogo = createDefaultLogo(tenant.tenantId!);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<any>();
  const [logo, setLogo] = useState<LogoDetails>(defaultLogo);

  const { isLoading, hasCreated, createError } = useSelector(
    (state: MainState) => ({
      isLoading: state.manage.isCreating,
      hasCreated: state.manage.hasCreated,
      createError: state.manage.createError
    }),
    shallowEqual
  );

  useEffect(() => {
    const { error } = manageSchema.validate(logo);
    setValidationError(error);
    if (tenant?.logoTitles?.includes(`${logo.title}${logo.type}`)) {
      setValidationError({ message: "Please provide a logo name that does not already exist." })
    }
  }, [logo]);

  const onSaveButtonClick = () => {
    setSubmitted(true);
    const { error } = manageSchema.validate(logo);
    if (!error) {
      onSubmit(logo);
    }
  }

  useEffect(() => {
    onUpdateTenant(logo.tenantId)
  }, [logo])

  return (
    <div className="modal-form">
      <Loader isLoading={isLoading} />
      {!isLoading && !hasCreated && !createError &&
        <>
          <fieldset className="modal-form-fields">
            <LogoFields logo={logo} tenant={tenant} updateLogo={setLogo} />
          </fieldset>
          <div className='modal-form-button-wrapper'>
            <button disabled={validationError !== undefined} onClick={onSaveButtonClick}>Save</button>
            {logo.value && validationError && <p className='error-message'>{validationError.message}</p>}
          </div>
        </>
      }
      {
        !isLoading && hasCreated &&
        <>
          <h3>Successfully added your logo {logo.title}!</h3>
        </>
      }
      {
        createError &&
        <>
          <p>Unable to load your logo. Ensure this is a tenant that you have access to edit. Otherwise, please contact your administrator.</p>
        </>
      }
    </div>
  );
}