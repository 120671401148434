import { TextArea as ValidatedTextArea } from '../../form/validatedTextArea';
import { jobSchema } from '../../../store/jobs/schema';

type PropTypes = {
  rows?: number,
  propName: string,
  label: string,
  job: Job, updateJob: (job: Job) => void,
  touchedOverride?: boolean
}

export const TextArea: React.FC<PropTypes> = ({ rows = 5, propName, label, job, updateJob, touchedOverride }) => {

  const onChange = (value: any) => updateJob({ ...job, [propName]: value });
  const schema = jobSchema.extract(propName);

  return (
    <ValidatedTextArea
      rows={rows}
      label={label}
      value={(job as any)[propName]}
      schema={schema}
      onChange={onChange}
      touchedOverride={touchedOverride}
    />
  )
};