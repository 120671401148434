import { ChangeEvent, useState } from 'react';

import { AnySchema } from 'joi';

type PropTypes = {
  rows?: number,
  disabled?: boolean,
  label: string,
  value: any,
  schema: AnySchema,
  onChange: (value: any) => void,
  touchedOverride?: boolean
}

export const TextArea: React.FC<PropTypes> = ({ rows = 5, disabled = false, label, value, schema, onChange, touchedOverride }) => {
  const [touched, setTouched] = useState<boolean>(false);

  const { error } = schema.validate(value);
  const validationMessage = error?.message.replace('"value"', label);

  const onInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTouched(true);
    onChange(event.currentTarget.value)
  };

  return (
    <>
      <label>{label}</label>
      {(touchedOverride || touched) && error && <p className="error-message">{validationMessage}</p>}
      <textarea
        rows={rows}
        disabled={disabled}
        placeholder={label}
        value={value}
        onChange={onInputChange}
      />
    </>
  )
};